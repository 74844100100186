<template>
  <v-container
    v-if="quote"
    id="quote-form"
    class="add-quote-container"
    :class="sideBarToggled"
    fluid
  >
    <v-layout v-if="!isActive">
      <v-layout row align-center class="deleted-warning">
        <CRIcon class="deleted-warning-icon" material :height="36" :width="36">
          error_color
        </CRIcon>
        <p style="margin: 0px">This quote has been deleted.</p>
      </v-layout>
    </v-layout>

    <v-layout v-if="!isRA || isModeView" class="sheet top-section" column>
      <v-container style="max-width: none" class="margin-a-0 padding-a-0">
        <v-layout row>
          <v-flex column>
            <h1 class="page-header">
              <v-layout row justify-start align-center>
                {{ modeTitle }} Route Quote
                <span v-if="quoteId" style="white-space: pre">
                  {{ `ID: ${quoteId}` }}
                </span>
                <TierBadge
                  v-if="showTierMarker"
                  class="margin-l-2"
                  :tier="tier"
                />
              </v-layout>
              <span v-if="quote.createdOn" class="timestamp">
                <br />
                <span class="label">Created:</span>
                {{ createdOnDisplayText }}
              </span>
              <span v-if="quote.updatedOn" class="timestamp">
                -
                <span class="label">Last Updated:</span>
                {{ updatedOnDisplayText }}
              </span>
              <span
                v-if="quote && quote.createdBy && !isModeAdd"
                class="timestamp"
              >
                -
                <span class="label">Created By:</span>
                {{ quote.createdBy.firstName }} {{ quote.createdBy.lastName }}
              </span>
            </h1>
          </v-flex>
          <v-btn
            v-if="isModeView && !isConverted && canCreateShuttleQuotes"
            color="primary"
            :disabled="!isActive"
            @click="editMode"
          >
            Edit Quote
          </v-btn>
          <v-menu v-if="isModeView">
            <template #activator="actionMenu">
              <v-btn
                id="quote-form-actions-menu"
                class="btn-secondaryaction"
                :loading="actionsMenuLoading"
                v-on="actionMenu.on"
              >
                Actions
                <v-icon>arrow_drop_down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-tile
                v-if="!isConverted"
                id="quote-form-actions-menu-view-checkout"
                :style="{ cursor: 'pointer' }"
                @click="viewCheckoutPage"
              >
                <v-list-tile-title>View Checkout</v-list-tile-title>
              </v-list-tile>

              <v-list-tile
                v-if="!isConverted && showApproveQuote"
                id="quote-form-actions-menu-approve-quote"
                :style="{ cursor: 'pointer' }"
                @click="showDialog('approveQuote')"
              >
                <v-list-tile-title>Approve Quote</v-list-tile-title>
              </v-list-tile>

              <v-list-tile
                id="quote-form-actions-menu-edit-temp-follow-up"
                :style="{ cursor: 'pointer' }"
                @click="updateLeadTempAndFollowUpDate"
              >
                <v-list-tile-title>CRM Actions</v-list-tile-title>
              </v-list-tile>

              <v-list-tile
                v-if="!quote.participatingInEmailCampaigns"
                id="quote-form-actions-menu-enable-emails"
                :style="{ cursor: 'pointer' }"
                @click="
                  (evt) =>
                    enableCampaign({
                      status: true,
                      quoteId: quote.quoteId,
                    }).then(() => {
                      quote.participatingInEmailCampaigns = true
                    })
                "
              >
                <v-list-tile-title>Enable Email Campaign</v-list-tile-title>
              </v-list-tile>

              <v-list-tile
                v-if="quote.participatingInEmailCampaigns"
                id="quote-form-actions-menu-disable-emails"
                :style="{ cursor: 'pointer' }"
                @click="
                  (evt) =>
                    enableCampaign({
                      status: false,
                      quoteId: quote.quoteId,
                    }).then(() => {
                      quote.participatingInEmailCampaigns = false
                    })
                "
              >
                <v-list-tile-title>Disable Email Campaign</v-list-tile-title>
              </v-list-tile>

              <v-list-tile
                v-if="isConverted"
                id="quote-form-actions-menu-link-to-contract"
                :style="{ cursor: 'pointer' }"
                @click="linkToContract"
              >
                <v-list-tile-title>Link To Contract</v-list-tile-title>
              </v-list-tile>
              <v-list-tile
                v-if="isBroker || isRAManager"
                id="quote-form-actions-menu-update-created-by"
                :style="{ cursor: 'pointer' }"
                @click="updateSentBy"
              >
                <v-list-tile-title>Update Sent By User</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-menu>
        </v-layout>

        <v-layout
          v-if="!showingMarketplaceViewForClient && !loadingQuoteReset"
          row
        >
          <v-form
            ref="leadsource-checkoutpage-form"
            style="width: 100%; display: inline-flex"
          >
            <v-layout padded wrap>
              <v-flex xs12>
                <v-layout>
                  <v-flex md12 lg6>
                    <v-layout>
                      <v-flex xs12>
                        <div style="display: flex; align-items: center">
                          <p class="margin-a-0 padding-r-2 font-16">
                            Quote Configuration
                          </p>
                          <v-tooltip right>
                            <template #activator="{ on }">
                              <div v-on="on">
                                <CRIcon
                                  view-box="0 0 24 20"
                                  :width="24"
                                  :height="24"
                                  color="#9e9e9e"
                                  class="cursor-pointer"
                                >
                                  info
                                </CRIcon>
                              </div>
                            </template>
                            <span>
                              CharterUP brand can quote all three pricing
                              methods, non CharterUP brands can only price
                              single price
                            </span>
                          </v-tooltip>
                        </div>
                      </v-flex>
                    </v-layout>
                    <v-form ref="quote-form-configurations" class="w-full">
                      <v-layout>
                        <v-flex xs4 class="padding-r-2">
                          <CRInput
                            v-if="!isModeAdd"
                            id="quote-form-lead-source"
                            v-model="leadSourceLabel"
                            label="Lead Source"
                            :disabled="true"
                          />
                          <CRInput
                            v-if="isModeAdd"
                            id="quote-form-lead-source"
                            :value="leadSourceLabel"
                            label="Lead Source"
                            type="autocomplete"
                            :items="suggestedLeadSources"
                            item-text="displayName"
                            item-key="leadSourceId"
                            :return-object="true"
                            :search-input.sync="leadSourceSearchTerm"
                            :clearable="true"
                            clear-icon="replay"
                            placeholder="Enter lead source name"
                            browser-autocomplete="off"
                            :disabled="
                              disableContractDetailFields || !isModeAdd
                            "
                            @input="selectLeadSourceFromList"
                            @click:clear="resetLeadSource"
                            @change="clearPricingTable = true"
                          />
                          <span
                            v-if="leadSourceId === null && showLeadSourceError"
                            class="error-message"
                          >
                            Lead Source Required
                          </span>
                        </v-flex>
                        <v-flex xs4 class="padding-r-2">
                          <CRInput
                            v-if="!isModeAdd"
                            id="quote-form-checkout-page"
                            :value="checkoutPageLabel"
                            label="Brand"
                            :disabled="true"
                          />
                          <CRInput
                            v-if="isModeAdd"
                            id="quote-form-checkout-page"
                            :key="`quote-form-checkout-page-${checkoutPageLabel}`"
                            :value="checkoutPageLabel"
                            required
                            type="autocomplete"
                            :items="suggestedCheckoutPages"
                            item-text="name"
                            item-key="companyCheckoutPageId"
                            :return-object="true"
                            label="Brand"
                            :clearable="true"
                            clear-icon="replay"
                            :disabled="
                              disableContractDetailFields ||
                              !isModeAdd ||
                              charterUpQuote
                            "
                            :search-input.sync="checkoutPageSearchTerm"
                            placeholder="Brand"
                            browser-autocomplete="off"
                            @click:clear="resetCheckoutPage"
                            @input="selectCheckoutPageFromList"
                          />
                          <span
                            v-if="
                              checkoutPageId === null &&
                              checkoutPageLabel !== 'CharterUP' &&
                              showCheckoutPageError
                            "
                            class="error-message"
                          >
                            Brand Required
                          </span>
                        </v-flex>
                        <v-flex xs4>
                          <CRInput
                            v-if="!isModeAdd"
                            id="quote-form-pricing-method"
                            :value="pricingMethodLabel"
                            label="Quote Pricing"
                            :disabled="true"
                          />
                          <CRSelect
                            v-if="isModeAdd"
                            id="quote-form-pricing-method"
                            :value="quote.pricingMethod"
                            :items="availablePricingMethods"
                            :disabled="!checkoutPageId"
                            label="Quote Pricing"
                            item-text="label"
                            item-value="key"
                            :loading="loading"
                            :rules="[
                              (val) =>
                                isNotEmpty(val) || 'Quote Pricing Is Required',
                            ]"
                            @input="selectQuotePricingMethod"
                          />
                        </v-flex>
                      </v-layout>
                    </v-form>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12>
                <v-layout>
                  <v-flex md12 lg6>
                    <v-layout>
                      <v-flex xs12>
                        <div style="display: flex; align-items: center">
                          <p class="margin-a-0 padding-r-2 font-16">
                            Other Configurations
                          </p>
                          <v-tooltip right>
                            <template #activator="{ on }">
                              <div v-on="on">
                                <CRIcon
                                  view-box="0 0 24 20"
                                  :width="24"
                                  :height="24"
                                  color="#9e9e9e"
                                  class="cursor-pointer"
                                >
                                  info
                                </CRIcon>
                              </div>
                            </template>
                            <span>
                              Contracts can only be added if they are already
                              created
                            </span>
                          </v-tooltip>
                        </div>
                      </v-flex>
                    </v-layout>
                    <v-layout>
                      <v-flex
                        v-if="isBroker || isSeniorISR || isMmeSales"
                        xs4
                        class="padding-r-2"
                      >
                        <CRSelect
                          id="quote-form-currency-type"
                          :value="currencyType"
                          label="Currency"
                          :items="['USD', 'CAD']"
                          :disabled="isModeView"
                          @input="changeCurrencyType"
                        />
                      </v-flex>
                      <v-flex xs4>
                        <CRInput
                          v-if="isBroker || isSeniorISR || isMmeSales"
                          id="quote-form-contract-name"
                          :value="contract"
                          label="Contract (Optional)"
                          type="autocomplete"
                          :items="suggestedContracts"
                          item-text="contractName"
                          item-key="quoteId"
                          :disabled="isModeView || disableContractDetailFields"
                          :return-object="true"
                          :search-input.sync="contractSearchTerm"
                          :clearable="true"
                          clear-icon="close"
                          placeholder="Enter Contract Name"
                          browser-autocomplete="off"
                          @input="selectContractFromList"
                        ></CRInput>
                      </v-flex>
                      <v-flex v-if="canEditTiers" class="padding-l-2" xs4>
                        <CRSelect
                          id="quote-form-tier"
                          :value="tierId"
                          label="Service Tier"
                          :items="tiers"
                          :disabled="isModeView"
                          item-text="label"
                          item-value="tierId"
                          @input="(value) => updateQuoteTier(value)"
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-form>
        </v-layout>
        <v-layout v-else-if="loadingQuoteReset" row align-center justify-center>
          <!-- Using primary keyword doesn't seem to set color here -->
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-layout>
      </v-container>
    </v-layout>

    <div>
      <v-flex row class="sheet pa-0">
        <UserSelector
          ref="customer-form"
          :value="customer || emptyCustomer"
          :quote-id="quote?.quoteId"
          :mode="mode"
          :is-customer-selected="isCustomerSelected"
          :past-quotes-button="customerId && isCustomerSelected"
          :show-past-quotes="showPastQuotes"
          :show-last-quote="showLastQuote"
          is-new-display
          :industry-required="true"
          :is-editing-customer="isEditingCustomer"
          @input="setCustomer($event)"
          @toggle-show-past-quotes="showPastQuotes = !showPastQuotes"
          @toggle-show-last-quote="toggleShowLastQuote"
          @set-is-customer-selected="
            (value) => {
              isCustomerSelected = value
            }
          "
          @set-is-editing-customer="
            (value) => {
              isEditingCustomer = value
            }
          "
        />

        <div v-if="shouldDisplayPastQuotesTable" class="results">
          <QuotePastQuotesTable
            :key="`customer-quote-table-${quote.customerId}`"
            :customer="customer"
            :last-quote-id="lastQuoteId"
            :show-last-quote="showLastQuote"
            @duplicate-trips="duplicateTrips"
          />
        </div>
      </v-flex>

      <section class="sheet">
        <v-layout align-center fill-height class="tabs">
          <v-tabs :value="currentTripIndex">
            <v-tab
              v-for="(trip, tripIndex) in trips"
              :key="`trip-tab-${tripIndex}`"
              :class="
                (validationResults &&
                  validationResults[tripIndex] &&
                  validationResults[tripIndex].hasFailures === true) ||
                (validationResults[`${tripIndex}-payment`] &&
                  validationResults[`${tripIndex}-payment`].hasFailures ===
                    true) ||
                (validationResults[`${tripIndex}-payment-method`] &&
                  validationResults[`${tripIndex}-payment-method`]
                    .hasFailures === true) ||
                (validationResults[`${tripIndex}-detail`] &&
                  validationResults[`${tripIndex}-detail`].hasFailures ===
                    true) ||
                (validationResults[`${tripIndex}-market`] &&
                  validationResults[`${tripIndex}-market`].hasFailures ===
                    true) ||
                shouldSplitTrip
                  ? 'quote-trip-tab failed-validation'
                  : 'quote-trip-tab'
              "
              @click="selectTrip(tripIndex)"
            >
              <v-tooltip top size="14" class="raised-tool-tip">
                <template #activator="{ on }">
                  <v-icon v-if="shouldSplitTrip" class="active-error" v-on="on">
                    error_outline
                  </v-icon>
                </template>
                <div>
                  To price this trip correctly,
                  <br />
                  it should be separated into multiple one-way trips.
                  <br />
                  Click Convert to Multi-Trip to update the quote.
                </div>
              </v-tooltip>
              <p class="margin-a-0 font-weight-600">
                {{ trip.routeName ? trip.routeName : `Trip ${tripIndex + 1}` }}
              </p>
              <v-btn
                v-if="!isModeView && trips.length > 1"
                :id="`quote-form-remove-trip-button-${tripIndex}`"
                v-model="currentTrip"
                icon
                class="icon-button-close"
                @click.stop="removeTrip(tripIndex)"
              >
                <v-icon class="tab-icon-close">close</v-icon>
              </v-btn>
              <a
                v-if="shouldSplitTrip"
                @click="confirmSplitTripDialog = !confirmSplitTripDialog"
              >
                Convert to Multi-Trip
              </a>
            </v-tab>
          </v-tabs>
          <v-flex>
            <v-menu v-if="!isModeView">
              <template #activator="tripActionMenu">
                <a
                  id="quote-form-add-button"
                  class="padding-a-2"
                  v-on="tripActionMenu.on"
                >
                  + Add
                </a>
              </template>
              <v-list>
                <v-list-tile
                  id="quote-form-add-new-trip-button"
                  :style="{ cursor: 'pointer' }"
                  @click="addTrip"
                >
                  <v-list-tile-title>Add New Trip</v-list-tile-title>
                </v-list-tile>
                <template v-for="(trip, tripIndex) in trips">
                  <v-list-tile
                    :id="`quote-form-duplicate-trip-from-previous-button-trip-${tripIndex}`"
                    :key="`trip_action_${tripIndex}`"
                    :style="{ cursor: 'pointer' }"
                    @click="duplicateTripFromPrevious(trip, tripIndex)"
                  >
                    <v-list-tile-title>
                      Copy
                      {{
                        trip.routeName
                          ? trip.routeName
                          : `Trip ${tripIndex + 1}`
                      }}
                    </v-list-tile-title>
                  </v-list-tile>
                </template>
              </v-list>
            </v-menu>
          </v-flex>
        </v-layout>

        <v-layout row wrap>
          <v-flex xs4>
            <v-btn-toggle
              ref="trip-details-toggle"
              v-model="toggleSubTabs"
              mandatory
              class="elevation-0 mt-4 trip-details-button"
            >
              <v-btn
                :id="`quote-form-trip-details-button-${selectedTripIndex}`"
                :class="subTabClass(selectedTripIndex, 'detail', 0)"
                flat
                @click="toggleSubTabs = 0"
              >
                Trip Details
              </v-btn>

              <v-btn
                :id="`quote-form-trip-payment-button-${selectedTripIndex}`"
                :class="subTabClass(selectedTripIndex, 'payment', 1)"
                flat
                style="border-radius: 0"
                @click="toggleSubTabs = 1"
              >
                Payment
              </v-btn>

              <v-btn
                :id="`quote-form-trip-notes-button-${selectedTripIndex}`"
                :class="subTabClass(selectedTripIndex, 'notes', 2)"
                flat
                style="border-radius: 0"
                @click="toggleSubTabs = 2"
              >
                Notes
              </v-btn>

              <v-btn
                :id="`quote-form-trip-recurrence-button-${selectedTripIndex}`"
                :class="subTabClass(selectedTripIndex, 'recurrence', 3)"
                flat
                style="border-radius: 0"
                @click="toggleSubTabs = 3"
              >
                Recurrence
              </v-btn>

              <v-btn
                :id="`quote-form-trip-contact-button-${selectedTripIndex}`"
                :class="subTabClass(selectedTripIndex, 'contact', 4)"
                flat
                @click="toggleSubTabs = 4"
              >
                Trip Contact
              </v-btn>
            </v-btn-toggle>
          </v-flex>
        </v-layout>

        <div v-show="toggleSubTabs === 0" class="tab-content">
          <QuoteFormTripDetailNew
            v-if="currentTrip"
            id="quote-form-trip-detail-new"
          />
        </div>

        <div v-show="toggleSubTabs === 1" class="tab-content">
          <QuoteFormTripPayment
            v-if="currentTrip"
            id="quote-form-trip-payment"
            :value="currentTrip"
            :trip-data="currentTrip"
            :can-open-charter-up-quote="canOpenCharterUpQuote"
            :validation-key="validationKey"
            :due-date-key="dueDateKey"
            :trip-index="currentTripIndex"
            :payment-types="paymentTypes"
            :quote="quote"
            :charge-types="chargeTypes"
            :rate-types="rateTypes"
            :exchange-rate="exchangeRate"
            :currency-type="quote.currencyType"
            :payment-method-types="paymentMethodTypes"
            :suggested-pricing="currentTrip.suggestedPricing"
            :live-mile-label="currentTrip.liveMileLabel"
            :dead-mile-label="currentTrip.deadMileLabel"
            :estimated-time-label="currentTrip.estimatedTimeLabel"
            :calculating-estimations="calculatingEstimations"
            :toggled="toggleSubTabs === 1"
            :mode="mode"
            :clear-pricing-table="clearPricingTable"
            :pricing-data="pricingData"
            :refresh-pricing="currentTrip.refreshPricing"
            :show-marketplace-features="showMarketplaceFeatures"
            :customer-account-defaults="customerAccountDefaults"
            @pricing-data="pricingData = $event"
            @get-pricing="getEstimations"
            @validation-results="captureValidationResults"
            @pricing-table-cleared="clearPricingTable = false"
            @refresh-pricing="currentTrip.refreshPricing = false"
            @update-trip-processing-percentage="
              updateTripProcessingPercentage(currentTripIndex, $event)
            "
          />
        </div>

        <v-container v-show="toggleSubTabs === 2" class="tab-content">
          Trip Notes
          <CRRichText
            v-if="currentTrip"
            :id="`quote-form-customer-notes-description-trip-${selectedTripIndex}`"
            :note="previousTripNotes.customerNotes"
            multi-line
            :read-only="isModeView"
            :disabled="isModeView"
            placeholder="Trip notes for you, the customer, and the operator."
            class="mb-4"
            @htmlchange="updateTripNoteHtml"
            @textchange="updateTripNoteText"
          />
          Office Notes
          <CRRichText
            v-if="currentTrip"
            :id="`quote-form-customer-notes-text-trip-${selectedTripIndex}`"
            :note="previousTripNotes.officeNotes"
            multi-line
            :read-only="isModeView"
            :disabled="isModeView"
            placeholder="Trip notes for internal use only (customers and operators will not see this)"
            class="mb-4"
            @htmlchange="updateOfficeNoteHtml"
            @textchange="updateOfficeNoteText"
          />
          <div v-if="isSuperAdmin">
            Driver Notes
            <CRRichText
              v-if="currentTrip"
              :id="`quote-form-driver-notes-text-trip-${selectedTripIndex}`"
              :note="previousTripNotes.driverNotes"
              multi-line
              :read-only="isModeView"
              :disabled="isModeView"
              :placeholder="`Trip notes for you and the driver (customers will not see this).`"
              class="mb-4"
              @htmlchange="updateDriverNoteHtml"
              @textchange="updateDriverNoteText"
            />
          </div>
        </v-container>

        <v-container v-show="toggleSubTabs === 3" class="tab-content">
          <template v-if="currentTrip">
            <RecurrencesInput
              :value="currentTrip.recurrences"
              :original-recurrence-event="recurrenceEventObjectForSelectedTrip"
              :time-zone="getTimeZoneForTrip(currentTrip)"
              :disabled="isModeView"
              @input="setRecurrences($event)"
              @validation-results="captureValidationResults"
            />

            <RecurrencesCalendar
              :value="currentTrip.recurrences"
              :original-recurrence-event="recurrenceEventObjectForSelectedTrip"
              :time-zone="getTimeZoneForTrip(currentTrip)"
              @update-exclusions="setRecurrenceExclusions($event)"
            />
          </template>
        </v-container>

        <v-container v-show="toggleSubTabs === 4" class="tab-content">
          <v-checkbox
            v-if="!isModeView && currentTrip"
            :id="`quote-form-checkbox-customer-notes-is-customer-${selectedTripIndex}`"
            :value="currentTrip.tripContactIsCustomer"
            background-color="white"
            solo
            :disabled="!quote.customer.customerId"
            color="primary"
            label="Trip Contact is Customer"
            @change="setTripContactIsCustomer(currentTripIndex, $event)"
          />
          <UserSelector
            v-if="currentTrip && !currentTrip.tripContactIsCustomer"
            :id="`quote-form-user-selector-is-selected-${selectedTripIndex}`"
            :value="currentTrip.tripContact || emptyTripContact"
            :quote-id="quote?.quoteId"
            :user-title="'Trip Contact'"
            :mode="mode"
            :is-customer-selected="currentTrip.isTripContactSelected"
            :populated-from-lead="populatedFromLead"
            @input="setTripContact(currentTripIndex, $event)"
            @set-is-customer-selected="
              (value) => {
                setIsTripContactSelectedModule({
                  tripIndex: currentTripIndex,
                  isTripContactSelected: value,
                })
              }
            "
          />
          <UserSelector
            v-else
            :value="customer || emptyCustomer"
            :quote-id="quote?.quoteId"
            :user-title="'Trip Contact'"
            mode="view"
            :is-customer-selected="true"
            :populated-from-lead="populatedFromLead"
          />
        </v-container>
      </section>
      <section>
        <v-layout row class="lead-temp-follow-up">
          <QuoteFormLeadFollowUpDetail
            ref="quote-form-lead-follow-up"
            style="width: 50%"
            :quote="quote"
            :mode="mode"
            @set-lead-temperature="setLeadTemperatureType"
            @set-lead-follow-up-date="setLeadFollowUpDate"
            @set-quote-note-text="setQuoteNoteText"
            @set-quote-note-html="setQuoteNoteHtml"
          />
          <v-divider vertical class="margin-x-5 margin-b-0" />
          <v-layout column style="display: inline; width: 50%">
            <v-checkbox
              v-if="!isModeView && !quote.contractId"
              :input-value="quote.soldOut"
              hide-details
              color="primary"
              label="Sold Out"
              @change="setSoldOutModule({ soldOut: $event })"
            />
            <v-checkbox
              v-if="!isModeView && !quote.contractId"
              :input-value="quote.isPendingConfirmation"
              hide-details
              color="primary"
              label="Pending Confirmation"
              @change="
                setIsPendingConfirmationModule({
                  isPendingConfirmation: $event,
                })
              "
            />
            <v-checkbox
              v-if="!isModeView || quote.isEnterprise"
              :input-value="quote.isEnterprise"
              hide-details
              color="primary"
              :disabled="isModeView"
              label="Enterprise"
              @change="isEnterpriseChecked($event)"
            />
            <v-form ref="quote-form-enterprise-fields">
              <v-layout
                v-if="isAccountExecutiveAndOpportunityIdEnabled"
                row
                class="margin-t-3"
              >
                <v-flex xs12>
                  <v-layout row>
                    <v-flex class="margin-r-2" xs6>
                      <AutoCompleteUser
                        label="Account Executive"
                        placeholder="Select Account Executive"
                        class="account-executive-autocomplete"
                        are-account-executives
                        :initial-user="quote.accountExecutiveId"
                        :disabled="isModeView"
                        hide-details
                        @user-selected="accountExecutiveSelected"
                      />
                      <span
                        v-if="showAccountExecutiveError"
                        class="error-message"
                      >
                        Account Executive Is Required
                      </span>
                    </v-flex>
                    <v-flex xs6>
                      <CRInput
                        :value="quote.opportunityId"
                        label="Opportunity ID"
                        :disabled="isModeView"
                        :rules="
                          quote.isEnterprise
                            ? [
                                isRequired(true, isNotEmpty, {
                                  req: 'Quote Opportunity ID Is Required',
                                  error: 'Quote Opportunity ID Is Required',
                                }),
                                (val) =>
                                  val.length === 18 ||
                                  'Quote Opportunity ID Must Be 18 Characters',
                              ]
                            : [
                                (val) =>
                                  !val ||
                                  val.length === 18 ||
                                  'Quote Opportunity ID Must Be 18 Characters',
                              ]
                        "
                        @change="
                          setOpportunityIdModule({ opportunityId: $event })
                        "
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-form>
            <v-layout row>
              <v-flex xs6>
                <TeamSelection
                  ref="quote-form-classifications"
                  :mode="mode"
                  :existing-product-classification-id="
                    quote.productClassificationId
                  "
                  :existing-sourcing-team-classification-id="
                    quote.sourcingTeamClassificationId
                  "
                  :existing-support-team-classification-id="
                    quote.supportTeamClassificationId
                  "
                  :disabled="isModeView"
                  @product-input="changeClassification('product', $event)"
                  @sourcing-team-input="
                    changeClassification('sourcing-team', $event)
                  "
                  @support-team-input="
                    changeClassification('support-team', $event)
                  "
                />
              </v-flex>
            </v-layout>
          </v-layout>
        </v-layout>
      </section>
      <section>
        <div class="quote-totals">
          <QuoteFormFooter
            ref="quote-form-footer"
            :quote="quote"
            :total="total"
            :recurring-total="recurringTotal"
            :exchange-rate="exchangeRate"
            :required-deposit="requiredDeposit"
            :mode="mode"
            :lock-save-button="lockSaveButton"
            :save-quote-handler="saveQuoteHandler"
            :set-date-times="setDateTimes"
            :show-marketplace-features="showMarketplaceFeatures"
            :payment-types="paymentTypes"
            :is-editing-customer="isEditingCustomer"
            @set-is-expiration-autoset="
              quote.isExpirationDateTimeAutoset = $event
            "
          />
        </div>
        <v-dialog
          v-model="confirmSplitTripDialog"
          :max-width="dialogType === 'approveBASQuote' ? '360px' : '600px'"
        >
          <Confirm
            :id="`quote-form-split-trips`"
            header="Convert to Multi-trip"
            message="Are you sure you would like to convert this single trip to multiple trips?  You will not be able to undo this action. Please be sure the client does not require the bus for the entire trip."
            confirm-button-text="Convert"
            :is-dialog="true"
            :loading="confirmSplitTripLoading"
            @confirm="initializeSplitTrip"
            @cancel="confirmSplitTripDialog = false"
          />
        </v-dialog>
        <v-dialog
          v-model="actionsDialog"
          :max-width="dialogType === 'approveBASQuote' ? '360px' : '600px'"
        >
          <Confirm
            v-if="dialogType === 'approveBASQuote'"
            :id="`quote-form`"
            header="Approve Quote"
            message="Are you sure you want to approve this quote?"
            confirm-button-text="Approve"
            :is-dialog="true"
            @confirm="approveBASQuote"
            @cancel="onCloseDialog"
          />
        </v-dialog>
      </section>
      <PaymentSidebarWrapper
        :id="`quote-form-collect-payment-new`"
        v-model="dialogType"
        :quote-id="quote.quoteId"
        :quote-hash="quote.hash"
        :quote-total="total"
        quote-message="Quote successfully approved"
        v-bind="$attrs"
        @close-modal="onCloseDialog"
        @quote-approved="quoteApproved"
      />
    </div>

    <v-snackbar v-model="saveQuoteError" color="error">
      {{ saveQuoteErrorMessage }}
    </v-snackbar>

    <v-dialog
      v-model="unsavedChangesWarningDialog"
      :max-width="dialogType === 'approveBASQuote' ? '360px' : '600px'"
    >
      <Confirm
        :id="`quote-form-unsaved-changes`"
        header="Unsaved Changes"
        message="Are you sure you want to leave this page? Any unsaved changes will be lost."
        confirm-button-text="Leave Page"
        cancel-button-text="Stay on Page"
        :is-dialog="true"
        @confirm="confirmLeave"
        @cancel="confirmStay"
      />
    </v-dialog>
    <v-dialog v-model="newQuoteStopDatesInPastDialog" max-width="600px">
      <Confirm
        :id="`new-quote-dates-in-past`"
        header="Trip Dates in Past"
        message="Looks like one or more pickup or dropoff dates are in the past. Are you sure you want to continue?"
        confirm-button-text="Continue & Create Quote"
        cancel-button-text="Cancel"
        :is-dialog="true"
        @confirm="confirmQuoteCreate"
        @cancel="confirmCancel"
      />
    </v-dialog>
  </v-container>
</template>

<script>
const INTERNAL_NOTE_TYPE = 1
const CUSTOMER_NOTE_TYPE = 2
const DRIVER_NOTE_TYPE = 3
const STANDARD_TIER_ID = 1
const CHARTERUP_CHECKOUT_PAGE = {
  id: 4,
  companyCheckoutPageId: 4,
  checkoutPageId: 4,
  key: 'charterup',
  name: 'CharterUP',
  label: 'CharterUP',
  internalName: 'charterup',
}

const PRICING_METHODS_MAP = [
  {
    key: 'single_price',
    label: 'Single Price',
  },
  // Disable for shuttle quote form
  // {
  //   key: 'bids',
  //   label: 'Bids',
  // },
  // Disabled until we support Category checkout in CR
  // {
  //   key: 'category',
  //   label: 'Category',
  // },
]

import op from 'simple-object-path'
import { DateTime } from 'luxon'
import { ChargeTypeId, SplitFeatureFlag } from '@/utils/enum'

import { v4 } from 'uuid'
import { mapActions, mapGetters } from 'vuex'
import { mask } from 'vue-the-mask'
import { authComputed } from '@/state/helpers'
import { callCenterComputed } from '@/state/helpers'
import customers from '@/services/customers'
import recurrences from '@/services/recurrences'
import exchangeRate from '@/services/exchangeRate'
import leadSources from '@/services/leadSources'
import checkoutPages from '@/services/checkoutPages'

import { deepClone, deepCloneClass } from '@/utils/deepClone'
import { filter } from '@/utils/filter'
import * as logger from '@/utils/logger'
import { scrubQuoteForAPI } from '@/utils/quoteUtils'
import { environmentPrefix } from '@/utils/env'

import { getDatetimeFromDateAndTimeStrings } from '@/components/quoteFormhelpers'
import { findPermissionByName } from '@/utils/permissions'

import AutoCompleteUser from '@/components/AutoCompleteUser.vue'
import Confirm from '@/components/Confirm.vue'
import RecurrencesInput from '@/components/RecurrencesInput.vue'
import RecurrencesCalendar from '@/components/RecurrencesCalendar.vue'
import QuotePastQuotesTable from '@/components/QuotePastQuotesTable.vue'
import QuoteFormFooter from '@/components/QuoteFormFooter.vue'
import QuoteFormLeadFollowUpDetail from '@/components/QuoteFormLeadFollowUpDetail.vue'
import UserSelector from '@/components/UserSelector.vue'
import PaymentSidebarWrapper from '@/components/PaymentSidebarWrapper.vue'
import QuoteFormTripDetailNew from '@/components/QuoteFormTripDetailNew.vue'
import QuoteFormTripPayment from '@/components/QuoteFormTripPayment.vue'
import CRTag from '@/cr/components/CRTag.vue'
import contracts from '@/services/contracts'
import { formatDateTimeForDisplay } from '@/utils/time'
import { Trip } from '@/classes/Trip'
import TeamSelection from '@/components/TeamSelection.vue'
import { EventBus } from '@/utils/event-bus'
import { isRequired, isNotEmpty } from '@/utils/validators'
import TierBadge from '@/components/TierBadge.vue'

export default {
  metaInfo() {
    return {
      title: 'Quotes',
    }
  },
  components: {
    TeamSelection,
    AutoCompleteUser,
    QuoteFormLeadFollowUpDetail,
    RecurrencesInput,
    RecurrencesCalendar,
    QuoteFormFooter,
    QuotePastQuotesTable,
    Confirm,
    PaymentSidebarWrapper,
    CRTag,
    QuoteFormTripDetailNew,
    UserSelector,
    QuoteFormTripPayment,
    TierBadge,
  },
  directives: { mask },
  beforeRouteLeave(to, from, next) {
    if (to.name && to.name === 'login') {
      next()
    }
    if (!this.isModeView && this.quoteChanged && !this.lockSaveButton) {
      this.unsavedChangesLeaveAction = next
      this.handleUnsavedChanges()
    } else {
      next()
    }
  },
  props: {
    clientQuoteView: { type: String, default: null },
  },
  data() {
    return {
      emptyCustomer: {
        id: null,
        firstName: null,
        lastName: null,
        phone: null,
        email: null,
      },
      emptyTripContact: {
        id: null,
        firstName: null,
        lastName: null,
        phone: null,
        email: null,
      },
      showExpirationDateError: false,
      confirmSplitTripDialog: false,
      confirmSplitTripLoading: false,
      oldManagedId: null,
      isBASQuote: false,
      managedIdErrors: [],
      managedIdErrorState: false,
      managedIdTimeout: null,
      showApproveQuote: false,
      populatedFromLead: false,
      dialogType: undefined,
      actionsDialog: false,
      exchangeRate: 1,
      showAlternativeCurrency: false,
      allowedToEcheckout: false,
      lockSaveButton: false,
      validationResults: {},
      onlyOption: false,
      customerAccount: null,
      calculatingEstimations: false,
      loggedInUserCompanyName: undefined,
      leadSourceSearchTerm: undefined,
      checkoutPageSearchTerm: undefined,
      contractSearchTerm: undefined,
      checkoutPageDebounce: undefined,
      validationKey: undefined,
      dueDateKey: undefined,
      saveQuoteError: undefined,
      saveQuoteErrorMessage: undefined,
      showDecisionDateError: false,
      toggleSubTabs: 0,
      quotesFilters: filter(),
      reservationsFilters: filter(),
      pastQuotes: [],
      pastReservations: [],
      saveForLater: true,
      saveAndConvert: false,
      quoteClone: null,
      isCustomerSelected: false,
      isLeadSourceSelected: false,
      isCheckoutPageSelected: false,
      pastShowAll: false,
      showCheckoutPageError: false,
      showLeadSourceError: false,
      showAccountExecutiveError: false,
      suggestedLeadSources: [],
      suggestedCheckoutPages: [],
      suggestedContracts: [],
      tripTypes: [],
      chargeTypes: [],
      vehicleTypes: [],
      rateTypes: [],
      paymentTypes: [],
      paymentMethodTypes: [],
      showPastQuotes: false,
      showLastQuote: false,
      lastQuoteId: undefined,
      showMarketRatesDialog: false,
      showMarketsRatesMap: false,
      hasCustomerFieldInputs: false,
      tripPaymentMethodTypes: [],
      total: null,
      recurringTotal: null,
      requiredDeposit: null,
      selectedTrip: 'trip-0',
      processingFeeDebounce: null,
      clearPricingTable: false,
      contract: null,
      disableContractDetailFields: false,
      pricingData: [],
      lead: null,
      loadingQuoteReset: false,
      sidebarDialog: false,
      tripConflicts: [],
      cachedQuote: null,
      quoteChanged: false,
      unsavedChangesWarningDialog: false,
      newQuoteStopDatesInPastDialog: false,
      unsavedChangesLeaveAction: null,
      isEditingCustomer: false,
      actionsMenuLoading: false,
      formSubmitted: false,
      customerAccountDefaults: null,
      isTiersEnabled: false,
    }
  },
  validations: {
    quote: {},
  },
  computed: {
    ...authComputed,
    ...callCenterComputed,
    ...mapGetters({
      quoteInProgress: 'quotes/quoteInProgress',
      createNewSelected: 'quotes/getCreateCustomerAccount',
      selectedCustomerAccount: 'quotes/selectedCustomerAccount',
      marketStoreChangesForMarketplaceSendToClient:
        'quotes/getSelectedMarketChanges',
      shouldSplitTrip: 'quotes/getShouldSplitTrip',
      isAccountExecutiveAndOpportunityIdEnabled:
        'featureToggles/isAccountExecutiveAndOpportunityIdEnabled',
        getTiers: 'tiers/getTiers',
    }),
    canCreateShuttleQuotes() {
      return this.$store.getters['auth/hasPermission']('canCreateShuttleQuotes')
    },
    quote() {
      return this.$store.getters['quoteForm/getQuote']
    },
    mode() {
      return this.$store.getters['quoteForm/getMode']
    },
    isModeAdd() {
      return this.mode === 'add'
    },
    isModeView() {
      return this.mode === 'view'
    },
    isModeEdit() {
      return this.mode === 'edit'
    },
    isActive() {
      return this.quote?.isActive === undefined || this.quote?.isActive
    },
    isConverted() {
      return this.quote?.isConverted || false
    },
    customer() {
      return this.quote?.customer || null
    },
    customerId() {
      return this.customer?.customerId || null
    },
    leadSource() {
      return this.quote?.leadSource || null
    },
    leadSourceId() {
      return this.leadSource?.id || null
    },
    leadSourceLabel() {
      return this.leadSource?.label || null
    },
    charterUpQuote() {
      return this.quote?.charterUpQuote
    },
    checkoutPage() {
      return this.quote?.checkoutPage
    },
    checkoutPageId() {
      return this.checkoutPage?.id || null
    },
    checkoutPageLabel() {
      return this.checkoutPage?.label || null
    },
    currencyType() {
      return this.quote?.currencyType || null
    },
    tierId() {
      return this.quote?.tierId || null
    },
    tier() {
      return this.quote?.tier
    },
    trips() {
      return this.quote?.trips || []
    },
    currentTrip() {
      return this.$store.getters['quoteForm/getCurrentTrip']
    },
    currentTripIndex() {
      return this.$store.getters['quoteForm/getCurrentTripIndex']
    },
    previousTripNotes() {
      let notes = this.quote.trips[this.selectedTripIndex]?.tripNotes
      if (notes) {
        let customerNotes = notes.find((note) => {
          return note.noteType === CUSTOMER_NOTE_TYPE
        })
        let officeNotes = notes.find((note) => {
          return note.noteType === INTERNAL_NOTE_TYPE
        })
        let driverNotes = notes.find((note) => {
          return note.noteType === DRIVER_NOTE_TYPE
        })

        let previousNotes = {}
        previousNotes.customerNotes =
          customerNotes?.html ||
          customerNotes?.note ||
          this.quote.trips[this.selectedTripIndex]?.description
        previousNotes.officeNotes =
          officeNotes?.html ||
          officeNotes?.note ||
          this.quote.trips[this.selectedTripIndex]?.notes
        previousNotes.driverNotes = driverNotes?.html || driverNotes?.note

        return previousNotes
      }
      return {}
    },
    recurrenceEventObjectForSelectedTrip() {
      if (!this.currentTrip) {
        return null
      }
      const name =
        this.currentTrip.routeName ||
        `Trip ${Number(this.currentTripIndex) + 1}`
      return {
        id: this.currentTrip.tripId,
        idKey: 'tripId',
        startDatetime: this.currentTrip?.stops?.[0]?.pickupDatetime || null,
        endDatetime:
          this.currentTrip?.stops.length > 1 &&
          this.currentTrip?.stops[this.currentTrip?.stops.length - 1]
            ?.pickupDatetime
            ? this.currentTrip.stops[this.currentTrip.stops.length - 1]
                .pickupDatetime
            : null,
        name,
      }
    },
    sideBarToggled() {
      const state = this.getSideBar()
      if (state) {
        return 'why'
      }
      return 'not'
    },
    showingMarketplaceViewForClient() {
      return this.showMarketplaceFeatures && this.clientQuoteView
    },
    selectedTripIndex() {
      const selectedTrip = this.selectedTrip || ''
      return selectedTrip.substring(
        selectedTrip.indexOf('-') + 1,
        selectedTrip.length
      )
    },
    modeTitle() {
      let modeTitleString = 'Add New'
      if (this.isModeView) {
        modeTitleString = 'View'
      }
      if (this.isModeEdit) {
        modeTitleString = 'Edit'
      }
      return modeTitleString
    },
    canOpenCharterUpQuote() {
      return findPermissionByName(
        this.currentUserProfile?.roles,
        'canSendCharterUPQuote'
      )
    },
    showMarketplaceFeatures() {
      return this.quote.pricingMethod === 'bids' && this.canOpenCharterUpQuote
    },
    prequoteLeadDetected() {
      return this.isModeAdd && this.lead && this.isOnCall
    },
    shouldDisplayPastQuotesTable() {
      return (
        this.isCustomerSelected &&
        (this.showPastQuotes || this.showLastQuote) &&
        this.quote.customer.id
      )
    },
    createdOnDisplayText() {
      return formatDateTimeForDisplay(this.quote.createdOn)
    },
    updatedOnDisplayText() {
      return formatDateTimeForDisplay(this.quote.updatedOn)
    },
    availablePricingMethods() {
      if (!this.checkoutPageId || this.checkoutPageId === 4) {
        return PRICING_METHODS_MAP
      } else {
        return [PRICING_METHODS_MAP.find((item) => item.key === 'single_price')]
      }
    },
    pricingMethodLabel() {
      if (!this.quote?.pricingMethod) {
        return ''
      }
      return (
        PRICING_METHODS_MAP.find(
          (item) => item.key === this.quote.pricingMethod
        )?.label || this.quote.pricingMethod
      )
    },
    canEditTiers() {
      return this.isTiersEnabled && findPermissionByName(
        this.currentUserProfile?.roles,
        'canEditTiers'
      )
    },
    tiers() {
      return this.getTiers
    },
    showTierMarker() {
      if (this.isTiersEnabled && this.tier) {
        return this.tier?.level > 1
      }
      return false
    },
  },
  watch: {
    quote: {
      deep: true,
      handler: 'handleQuoteChange',
    },
    async leadSourceSearchTerm(value) {
      await this.searchLeadSourceList(value)
    },
    async checkoutPageSearchTerm(value) {
      await this.searchCheckoutPagesList(value)
    },
    async contractSearchTerm(value) {
      await this.searchContractsList(value)
    },
    selectedTrip() {
      this.toggleSubTabs = 0
    },
    marketStoreChangesForMarketplaceSendToClient(markets) {
      for (const [keyTripIndex, market] of Object.entries(markets)) {
        if (market) {
          this.trips[keyTripIndex].pricingMarket = market.marketId
        }
      }
    },
    prequoteLeadDetected(value) {
      if (value) {
        this.fillLeadSourceFromLead()
      }
    },
    // Maintain charterUpQuote value but slowly de-reference it over time
    'quote.pricingMethod'(newVal) {
      this.setCharterUpQuoteModule({ charterUpQuote: newVal === 'bids' })
    },
    $route: async function (to, from) {
      if (to.path === from.path) {
        return
      }
      Object.assign(this.$data, this.$options.data())
      this.setModeModule({ mode: to.params.mode })
      if (
        to.params.mode === 'view' &&
        (from.params.mode === 'add' || from.params.mode === 'edit')
      ) {
        this.resetQuoteForm(false, false)
      } else {
        this.resetQuoteForm()
      }

      this.$validator.reset()
      this.$refs['customer-form'].$refs.form.resetValidation()
    },
    availablePricingMethods(pricingMethodsList) {
      if (pricingMethodsList?.length === 1) {
        this.setQuotePricingMethodModule({
          pricingMethod: pricingMethodsList[0].key,
        })
      }
    },
    'quote.customer.customerId'(value) {
      if (value && this.isModeAdd) {
        this.loadCustomerAccountDefaults(value)
        this.loadCustomerServiceTier(value)
      }
    },
    '$store.state.split.isReady': {
      async handler(value) {
        if (!value) {
          return
        }
        this.isTiersEnabled = await this.isFeatureEnabled(
          SplitFeatureFlag.ServiceTier
        )
      },
      immediate: true,
    },
  },
  beforeMount() {
    const mode = this.$route.params.mode
    this.setModeModule({ mode })
  },
  async mounted() {
    await this.setVehicleTypesModule()
    if (this.isModeAdd) {
      this.initializeQuoteModule()
    }
    await this.resetQuoteForm(
      false,
      this.isModeEdit || this.isModeView ? false : true
    )
    const contractId = this.$route?.query?.contractId
    if (contractId) {
      this.populateContractDetails(contractId)
    }

    this.searchCheckoutPagesList('')

    EventBus.$on('get-estimations', () => {
      this.getEstimations()
    })
    EventBus.$on('set-pricing-method', (pricingMethod) => {
      this.setPricingMethod(pricingMethod)
    })
    EventBus.$on('set-fare', (fare) => {
      this.setFare(fare)
    })
    EventBus.$on('set-payment-type-id', (paymentTypeId) => {
      this.setPaymentTypeId(paymentTypeId)
    })
    EventBus.$on('set-due-date', (dueDate) => {
      this.setDueDate(dueDate)
    })
    EventBus.$on('set-charge-type-id', (chargeIndex, chargeTypeId) => {
      this.setChargeTypeIdModule({ chargeIndex, chargeTypeId })
    })
    EventBus.$on('set-charge-amount', (chargeIndex, amount) => {
      this.setChargeAmountModule({ chargeIndex, amount })
    })
    EventBus.$on('add-charge', () => {
      this.addChargeModule()
    })
    EventBus.$on('remove-charge', (chargeIndex) => {
      this.removeChargeModule({ chargeIndex })
    })
    EventBus.$on('set-payment-method-allowed', (paymentMethodIndex, value) => {
      this.setPaymentMethodAllowedModule({ paymentMethodIndex, value })
    })
    EventBus.$on('set-deposit-percentage', (depositPercentage) => {
      this.setDepositPercentageModule({ depositPercentage })
    })
    EventBus.$on('set-rate-type-id', (rateIndex, rateTypeId) => {
      this.setRateTypeIdModule({ rateIndex, rateTypeId })
    })
    EventBus.$on('set-rate-amount', (rateIndex, amount) => {
      this.setRateAmountModule({ rateIndex, amount })
    })
    EventBus.$on('add-rate', () => {
      this.addRateModule()
    })
    EventBus.$on('remove-rate', (rateIndex) => {
      this.removeRateModule({ rateIndex })
    })
    EventBus.$on('set-processing-fee-charge', (processingFeeCharge) => {
      this.setProcessingFeeChargeModule({
        tripIndex: this.currentTripIndex,
        processingFeeCharge,
      })
    })
    EventBus.$on('set-pricing-market', (pricingMarket) => {
      this.setPricingMarketModule({ pricingMarket })
    })
    EventBus.$on('set-auto-detected-nearest-market', (market) => {
      this.setAutoDetectedNearestMarketModule({ market })
    })
    EventBus.$on('set-trip-total', (total) => {
      this.setTripTotalModule({ total })
    })
  },
  methods: {
    ...mapGetters({
      getSideBar: 'dashboard/getSideBar',
    }),
    ...mapActions({
      initializeQuoteModule: 'quoteForm/initialize',
      setQuoteModule: 'quoteForm/setQuote',
      setModeModule: 'quoteForm/setMode',
      setDecisionDateModule: 'quoteForm/setDecisionDate',
      setExpirationDateModule: 'quoteForm/setExpirationDate',
      setExpirationTimezoneModule: 'quoteForm/setExpirationTimezone',
      setCustomerModule: 'quoteForm/setCustomer',
      setCustomerAccount: 'quoteForm/setCustomerAccount',
      setCurrentTripIndexModule: 'quoteForm/setCurrentTripIndex',
      setRouteNameModule: 'quoteForm/setRouteName',
      setProductClassificationIdModule: 'quoteForm/setProductClassificationId',
      setSourcingTeamClassificationIdModule:
        'quoteForm/setSourcingTeamClassificationId',
      setSupportTeamClassificationIdModule:
        'quoteForm/setSupportTeamClassificationId',
      setMinQualityModule: 'quoteForm/setMinQuality',
      setAccountExecutiveIdModule: 'quoteForm/setAccountExecutiveId',
      removeTripModule: 'quoteForm/removeTrip',
      setTripDescriptionModule: 'quoteForm/setTripDescription',
      setTripNotesModule: 'quoteForm/setTripNotes',
      addTripNoteModule: 'quoteForm/addTripNote',
      updateTripNoteModule: 'quoteForm/updateTripNote',
      updateTripNoteHtmlModule: 'quoteForm/updateTripNoteHtml',
      setTripContactIsCustomerModule: 'quoteForm/setTripContactIsCustomer',
      setIsTripContactSelectedModule: 'quoteForm/setIsTripContactSelected',
      setTripContactModule: 'quoteForm/setTripContact',
      setTripPaymentMethodsModule: 'quoteForm/setTripPaymentMethods',
      setTripsRefreshPricingModule: 'quoteForm/setTripsRefreshPricing',
      setLeadSourceModule: 'quoteForm/setLeadSource',
      setCharterUpQuoteModule: 'quoteForm/setCharterUpQuote',
      setCheckoutPageModule: 'quoteForm/setCheckoutPage',
      setCheckoutPageIdModule: 'quoteForm/setCheckoutPageId',
      setQuotePricingMethodModule: 'quoteForm/setQuotePricingMethod',
      setCurrencTypeModule: 'quoteForm/setCurrencyType',
      setTierIdModule: 'quoteForm/setTierId',
      setTierModule: 'quoteForm/setTier',
      addTripModule: 'quoteForm/addTrip',
      setRecurrencesModule: 'quoteForm/setRecurrences',
      setRecurrenceExclusionsModule: 'quoteForm/setRecurrenceExclusions',
      setLeadTemperatureTypeIdModule: 'quoteForm/setLeadTemperatureTypeId',
      setLeadFollowUpDateModule: 'quoteForm/setLeadFollowUpDate',
      setTripsModule: 'quoteForm/setTrips',
      setEstimationModule: 'quoteForm/setEstimation',
      setContractIdModule: 'quoteForm/setContractId',
      setTotalModule: 'quoteForm/setTotal',
      setAllowEcheckoutModule: 'quoteForm/setAllowEcheckout',
      setAttachPdfToEmailModule: 'quoteForm/setAttachPdfToEmail',
      setCreatedByModule: 'quoteForm/setCreatedBy',
      setProcessingFeePercentageModule: 'quoteForm/setProcessingFeePercentage',
      setFareModule: 'quoteForm/setFare',
      setPricingMethodModule: 'quoteForm/setPricingMethod',
      setPaymentTypeIdModule: 'quoteForm/setPaymentTypeId',
      setDueDateModule: 'quoteForm/setDueDate',
      setChargeTypeIdModule: 'quoteForm/setChargeTypeId',
      setChargeAmountModule: 'quoteForm/setChargeAmount',
      addChargeModule: 'quoteForm/addCharge',
      removeChargeModule: 'quoteForm/removeCharge',
      setPaymentMethodAllowedModule: 'quoteForm/setPaymentMethodAllowed',
      setMarketplaceDiscountPercentModule:
        'quoteForm/setMarketplaceDiscountPercent',
      setMarketplaceMarkupPercentModule:
        'quoteForm/setMarketplaceMarkupPercent',
      setDepositPercentageModule: 'quoteForm/setDepositPercentage',
      setRateTypeIdModule: 'quoteForm/setRateTypeId',
      setRateAmountModule: 'quoteForm/setRateAmount',
      addRateModule: 'quoteForm/addRate',
      removeRateModule: 'quoteForm/removeRate',
      setProcessingFeeChargeModule: 'quoteForm/setProcessingFeeCharge',
      setPricingMarketModule: 'quoteForm/setPricingMarket',
      setAutoDetectedNearestMarketModule:
        'quoteForm/setAutoDetectedNearestMarket',
      setRecurringTotalModule: 'quoteForm/setRecurringTotal',
      setRequiredDepositModule: 'quoteForm/setRequiredDeposit',
      addQuoteNoteModule: 'quoteForm/addQuoteNote',
      updateQuoteNoteModule: 'quoteForm/updateQuoteNote',
      updateQuoteNoteHtmlModule: 'quoteForm/updateQuoteNoteHtml',
      setSelectedTripVehicleGroupModule:
        'quoteForm/setSelectedTripVehicleGroup',
      setTripTotalModule: 'quoteForm/setTripTotal',
      setSoldOutModule: 'quoteForm/setSoldOut',
      setIsPendingConfirmationModule: 'quoteForm/setIsPendingConfirmation',
      setIsEnterpriseModule: 'quoteForm/setIsEnterprise',
      setAccountExecutiveModule: 'quoteForm/setAccountExecutive',
      setOpportunityIdModule: 'quoteForm/setOpportunityId',
      saveQuote: 'quotes/saveQuote',
      getQuoteTypes: 'types/getQuoteTypes',
      setVehicleTypesModule: 'types/setVehicleTypes',
      updateV2Quote: 'quotes/updateV2Quote',
      createV2Quote: 'quotes/createV2Quote',
      showAlert: 'app/showAlert',
      convert: 'quotes/convert',
      getQuoteTripEstimations: 'quotes/getQuoteTripEstimations',
      getSplitTrips: 'quotes/getSplitTrips',
      setShouldSplitTrip: 'quotes/setShouldSplitTrip',
      getV2Quote: 'quotes/getV2Quote',
      enableCampaign: 'quotes/changeEmailCampaignStatus',
      isFeatureEnabled: 'split/isFeatureEnabled',
    }),
    isRequired,
    isNotEmpty,
    selectTrip(tripIndex) {
      this.setCurrentTripIndex(tripIndex)
      this.setSelectedTripVehicleGroupModule({
        tripVehicleGroupIndex: 0,
        tripIndex,
        selected: true,
      })
    },
    setCustomer(customer) {
      this.setCustomerModule({ customer })
    },
    setTripContact(tripIndex, tripContact) {
      this.emptyTripContact = tripContact
      this.setTripContactModule({ tripIndex, tripContact })
    },
    setTripContactIsCustomer(tripIndex, value) {
      this.setTripContactIsCustomerModule({
        tripIndex,
        tripContactIsCustomer: value,
      })
    },
    setRecurrences(recurrences) {
      this.setRecurrencesModule({
        tripIndex: this.currentTripIndex,
        recurrences,
      })
    },
    setRecurrenceExclusions(recurrenceExclusions) {
      this.setRecurrenceExclusionsModule({
        tripIndex: this.currentTripIndex,
        recurrenceExclusions,
      })
    },
    setFare(fare) {
      this.setFareModule({ fare })
    },
    setPricingMethod(pricingMethod) {
      this.setPricingMethodModule({ pricingMethod })
    },
    setPaymentTypeId(paymentTypeId) {
      this.setPaymentTypeIdModule({ paymentTypeId })
    },
    setDueDate(dueDate) {
      this.setDueDateModule({ dueDate })
    },
    updateTripProcessingPercentage(tripIndex, event) {
      if (this.processingFeeDebounce) {
        clearTimeout(this.processingFeeDebounce)
      }
      this.processingFeeDebounce = setTimeout(() => {
        const processingFee = event
        if (processingFee > 100) {
          this.setProcessingFeePercentageModule({
            tripIndex,
            processingFeePercentage: 100,
          })
        } else if (processingFee < 0) {
          this.setProcessingFeePercentageModule({
            tripIndex,
            processingFeePercentage: 0,
          })
        } else {
          this.setProcessingFeePercentageModule({
            tripIndex,
            processingFeePercentage: Math.round(processingFee),
          })
        }
      }, 500)
    },
    handleUnsavedChanges() {
      this.unsavedChangesWarningDialog = true
    },
    confirmLeave() {
      this.unsavedChangesWarningDialog = false
      this.unsavedChangesLeaveAction()
    },
    confirmStay() {
      this.unsavedChangesWarningDialog = false
      this.unsavedChangesLeaveAction(false)
    },
    confirmQuoteCreate() {
      this.newQuoteStopDatesInPastDialog = false
      this.lockSaveButton = true
      setTimeout(async () => {
        this.createV2QuoteWrapper(
          this.saveForLater,
          this.quoteClone,
          this.saveAndConvert
        )
      }, 1000)
      this.lockSaveButton = false
    },
    confirmCancel() {
      this.newQuoteStopDatesInPastDialog = false
      this.lockSaveButton = false
    },
    updateTripNoteText(value) {
      this.updateNotesForTripIndex(
        this.selectedTripIndex,
        CUSTOMER_NOTE_TYPE,
        value
      )
    },
    updateTripNoteHtml(value) {
      this.updateHtmlNotesForTripIndex(
        this.selectedTripIndex,
        CUSTOMER_NOTE_TYPE,
        value
      )
    },
    updateOfficeNoteText(value) {
      this.updateNotesForTripIndex(
        this.selectedTripIndex,
        INTERNAL_NOTE_TYPE,
        value
      )
    },
    updateOfficeNoteHtml(value) {
      this.updateHtmlNotesForTripIndex(
        this.selectedTripIndex,
        INTERNAL_NOTE_TYPE,
        value
      )
    },
    updateDriverNoteText(value) {
      this.updateNotesForTripIndex(
        this.selectedTripIndex,
        DRIVER_NOTE_TYPE,
        value
      )
    },
    updateDriverNoteHtml(value) {
      this.updateHtmlNotesForTripIndex(
        this.selectedTripIndex,
        DRIVER_NOTE_TYPE,
        value
      )
    },
    updateNotesForTripIndex(tripIndex, noteType, note) {
      if (noteType == CUSTOMER_NOTE_TYPE) {
        this.setTripDescriptionModule({ tripIndex, description: note })
      }
      if (noteType == INTERNAL_NOTE_TYPE) {
        this.setTripNotesModule({ tripIndex, notes: note })
      }

      let filteredNotes = this.quote.trips[tripIndex]?.tripNotes?.find(
        (note) => note.noteType === noteType
      )
      if (filteredNotes) {
        this.updateTripNoteModule({
          tripIndex,
          note: filteredNotes,
          newNoteText: note,
        })
      } else {
        const noteObj = {
          note: note,
          createdBy: this?.currentUser?.userId,
          noteType: noteType,
        }
        this.addTripNoteModule({ tripIndex, note: noteObj })
      }
    },
    updateHtmlNotesForTripIndex(tripIndex, noteType, html) {
      let filteredNotes = this.quote.trips[tripIndex]?.tripNotes?.find(
        (note) => note.noteType === noteType
      )
      if (filteredNotes) {
        this.updateTripNoteHtmlModule({ tripIndex, note: filteredNotes, html })
      } else {
        const noteObj = {
          html: html,
          createdBy: this?.currentUser?.userId,
          noteType: noteType,
        }
        this.addTripNoteModule({ tripIndex, note: noteObj })
      }
    },
    async populateContractDetails(contractId) {
      const contractResponse = await contracts
        .getContract(contractId)
        .catch((error) => {
          logger.error(error)
        })
      this.suggestedContracts.push(contractResponse.data)
      this.contract = contractResponse.data

      const leadSource = this.contract.leadSourceType
      let leadSourceForList = {
        leadSourceId: leadSource.id,
        displayName: leadSource.label,
        internalName: leadSource.key,
        partnerCompanyId: leadSource.partnerCompanyId,
      }
      this.suggestedLeadSources.push(leadSourceForList)
      this.selectLeadSourceFromList(leadSourceForList)

      let checkoutPage
      if (this.contract.checkoutPage) {
        checkoutPage = {
          companyCheckoutPageId: this.contract.checkoutPage.checkoutPageId,
          name: this.contract.checkoutPage.label,
          internalName: this.contract.checkoutPage.key,
        }
      } else {
        checkoutPage = CHARTERUP_CHECKOUT_PAGE
      }
      this.suggestedCheckoutPages.push(checkoutPage)
      this.selectCheckoutPageFromList(checkoutPage)
      this.disableContractDetailFields = true
      this.setContractIdModule({
        contractId: this.contract?.contractId || this.contract?.quoteId || null,
      })
    },
    linkToContract() {
      this.$store.dispatch('app/openDialog', {
        component: () =>
          import('@/components/QuoteFormFooterLinkToContract.vue'),
        data: {
          quoteId: this.quote.quoteId,
          existingContract: this.contract,
        },
      })
    },
    updateSentBy() {
      const component = () => import('@/components/QuoteCreatedBySidebar.vue')
      this.$store.dispatch('app/openSidebarDialog', {
        data: {
          title: 'Update Sent By User',
          quoteId: this.quote.quoteId,
        },
        component,
      })
    },
    updateLeadTempAndFollowUpDate() {
      const component = () =>
        import('@/components/QuoteFormCRMActionsSidebar.vue')
      this.$store.dispatch('app/openSidebarDialog', {
        data: {
          title: 'CRM Actions',
          quoteId: this.quote.quoteId,
        },
        component,
      })
    },
    updateQuoteTier(tierId) {
      const tier = this.tiers.find((tier) => tier.tierId === tierId)
      if (tier) {
        this.setTierIdModule({ tierId })
        this.setTierModule({ tier: deepClone(tier) })
      }
    },
    async resetQuoteForm(maintainLoading = false, scrubRecurrenceIds = true) {
      this.loadingQuoteReset = true
      this.lastQuoteId = null
      this.loggedInUserCompanyName =
        this.currentUser?.companyName || 'CharterUP'

      if (this.$route.params.id) {
        this.quoteId = parseInt(this.$route.params.id, 10)
        const quoteResult = await this.getV2Quote(this.quoteId)
        const quote = quoteResult?.data?.quote
        if (quote) {
          this.scrubQuoteFromAPI(quote, scrubRecurrenceIds)
          this.setQuoteModule({ quoteData: quote })
          const { trips = [] } = this.quote
          this.isBASQuote = trips.some((trip) => trip?.paymentType?.id === 3)
          this.showApproveQuote = trips.some(
            (trip) => trip?.paymentType?.id === 3
          )
        }
      } else if (this.$route.query.d) {
        const quoteResult = await this.getV2Quote(this.$route.query.d)
        const quote = quoteResult?.data?.quote
        if (quote) {
          this.scrubQuoteFromAPI(quote, scrubRecurrenceIds)
          delete quote.id
          delete quote.quoteId
          delete quote.createdOn
          delete quote.updatedOn
          delete quote.selfServeId
          quote.isConfirmed = false
          quote.isConverted = false
          quote.isSelfServe = false
          quote.leadTemperatureTypeId = null
          quote.leadFollowUpDate = DateTime.local().plus({ days: 2 })
          quote.quoteNotes = []
          this.isConverted = quote.isConverted
          const { trips = [] } = quote
          this.isBASQuote = trips.some((trip) => trip?.paymentType?.id === 3)
          for (const trip of trips) {
            this.duplicateTrip(trip)
          }
          this.trips = trips
          this.showApproveQuote = trips.some(
            (trip) => trip?.paymentType?.id === 3
          )

          this.setQuoteModule({ quoteData: quote })
        }
      } else {
        this.setTotalModule({ total: this.total })
      }

      this.$nextTick(() => this.getEstimations())

      const quoteTypesData = await this.getQuoteTypes().catch((e) => e)
      const quoteTypes = quoteTypesData.data || {}
      this.paymentTypes = quoteTypes.payments
      this.rateTypes = quoteTypes.rates
      this.vehicleTypes = quoteTypes.vehicleTypes.filter(
        (vehicleType) => vehicleType.key !== 'unassigned'
      )
      this.tripTypes = quoteTypes.trips
      this.chargeTypes = quoteTypes.charges
      this.paymentMethodTypes = quoteTypes.paymentMethods

      const validPaymentMethodTypes = ['ach', 'credit_card', 'check']
      this.paymentMethodTypes = this.paymentMethodTypes.filter(
        (paymentMethod) => validPaymentMethodTypes.includes(paymentMethod.key)
      )
      const validPaymentTypes = [
        'full_payment',
        'down_payment',
        'bill_after_services',
      ]
      this.paymentTypes = this.paymentTypes.filter((paymentType) =>
        validPaymentTypes.includes(paymentType.key)
      )

      this.chargeTypes = this.chargeTypes.map((chargeType) => {
        if (
          ['processing_fees', 'trip_change', 'refund_adjustment'].includes(
            chargeType.key
          )
        ) {
          chargeType.hidden = true
        }
        return chargeType
      })
      this.tripPaymentMethodTypes = this.paymentMethodTypes.map(
        (paymentMethodType) => {
          return { isAllowed: false, paymentMethodType }
        }
      )
      if (this.isModeAdd) {
        this.setTripPaymentMethodsModule({
          tripIndex: 0,
          paymentMethods: deepClone(this.tripPaymentMethodTypes),
        })
      }

      this.$refs['customer-form'].resetCustomerFilters()
      this.trips = this.quote.trips

      if (this.currentUser.company.checkoutTypeId === 1) {
        this.allowedToEcheckout = true

        this.setAllowEcheckoutModule({ allowEcheckout: true })
        this.setAttachPdfToEmailModule({ attachPdfToEmail: false })
      } else {
        this.setAllowEcheckoutModule({ allowEcheckout: false })
        this.setAttachPdfToEmailModule({ attachPdfToEmail: true })
        this.onlyOption = true
      }
      if (this.quote.contractId && (this.isModeView || this.isModeEdit)) {
        const contract = await contracts
          .getContract(this.quote.contractId)
          .catch((error) => {
            logger.error(error)
          })
        this.suggestedContracts.push(contract.data)
        this.contract = contract.data
      }
      if (!maintainLoading) {
        this.loadingQuoteReset = false
      }
      const userResponse = await this.$store.dispatch(
        'users/getUser',
        this.quote.createdById || this.currentUser.userId
      )
      const userData = userResponse.data
      if (userData.user) {
        this.setCreatedByModule({ createdBy: userData.user })
      }
    },
    fillLeadSourceFromLead() {
      this.setLeadSourceModule({ leadSource: this.lead.leadSourceType })
      this.leadSourceSearchTerm = this.lead.leadSourceType.label
    },
    setDateTimes({ decisionDate, expirationDate, expirationTimezone }) {
      if (decisionDate) {
        this.setDecisionDateModule({ decisionDate })
      }
      if (expirationDate) {
        this.setExpirationDateModule({ expirationDate })
      }
      if (expirationTimezone) {
        this.setExpirationTimezoneModule({ expirationTimezone })
      }
    },
    addTrip() {
      const trip = new Trip()
      trip.paymentMethods = deepClone(this.tripPaymentMethodTypes)
      this.addTripModule({ trip })
    },
    removeTrip(tripIndex) {
      this.removeTripModule({ tripIndex })
      this.setCurrentTripIndexModule({ currentTripIndex: 0 })
    },
    async getEstimations() {
      if (typeof this.pricingDebounce !== 'undefined') {
        clearTimeout(this.pricingDebounce)
      }
      this.calculatingEstimations = true
      this.pricingDebounce = setTimeout(async () => {
        let quoteClone = deepClone(this.quote)
        quoteClone = scrubQuoteForAPI(quoteClone, true)
        const estimationsData = await this.getQuoteTripEstimations(
          quoteClone
        ).catch((e) => e)
        const estimationLists = estimationsData?.data || []
        for (const estimation of estimationLists) {
          this.setEstimationModule({ tripIndex: estimation.tripId, estimation })
        }
        this.$nextTick(() => {
          this.calculatingEstimations = false
          this.$forceUpdate()
        })
      }, 1000)
    },
    isEnterpriseChecked(value) {
      this.setIsEnterpriseModule({ isEnterprise: value })
      if (!value) {
        this.setAccountExecutiveModule({
          accountExecutiveId: null,
          accountExecutiveName: null,
        })
        this.setOpportunityIdModule({ opportunityId: null })
      }
    },
    accountExecutiveSelected(user) {
      this.setAccountExecutiveModule({
        accountExecutiveId: user.userId,
        accountExecutiveName: `${user.firstName} ${user.lastName}`,
      })
      this.showAccountExecutiveError = false
    },
    async handleQuoteChange(quoteValue) {
      let allTripRecurringTotal = 0
      let allTripDeposit = 0
      let allTripTotal = 0
      const { trips = [] } = quoteValue

      await Promise.all(
        trips.map(async (trip) => {
          const { depositPercentage = 0 } = trip
          const { tripTotal, recurringTripTotal } = await this.singleTripTotal(
            trip
          )
          allTripRecurringTotal += recurringTripTotal
          allTripTotal += tripTotal
          if (trip.paymentType.id === 2 || trip.paymentTypeId === 2) {
            allTripDeposit += recurringTripTotal * (depositPercentage / 100)
          } else if (trip.paymentType.id === 1 || trip.paymentTypeId === 1) {
            allTripDeposit += recurringTripTotal
          }
        })
      )
      this.recurringTotal = allTripRecurringTotal
      this.total = allTripTotal
      this.requiredDeposit = allTripDeposit
      this.setTotalModule({ total: allTripTotal })
      this.setRecurringTotalModule({ recurringTotal: allTripRecurringTotal })
      this.setRequiredDepositModule({ requiredDeposit: allTripDeposit })
    },
    async singleTripTotal(trip) {
      const { charges = [], processingFeePercentage } = trip
      const tripRecurrences = trip.recurrences
      const recurrenceCount =
        (await tripRecurrences.reduce(async (count, recurrence) => {
          const {
            data: { recurrenceEventDateList },
          } = await recurrences.getRecurrenceEventDateList([recurrence])
          count += recurrenceEventDateList.length
          return count
        }, 0)) + 1
      const chargesTotal = charges.reduce(
        (chargeTotal, charge) => chargeTotal + parseFloat(charge.amount || 0),
        0
      )

      const tripProcessingFee = (chargesTotal * processingFeePercentage) / 100
      const tripMarkupAmount =
        (chargesTotal * trip.marketplaceMarkupPercent || 0) / 100
      const marketplaceDiscountMultiplier =
        1 - (trip.marketplaceDiscountPercent || 0) / 100
      const tripTotal =
        (chargesTotal + parseFloat(tripProcessingFee) + tripMarkupAmount) *
        marketplaceDiscountMultiplier

      const recurringTripTotal = tripTotal * recurrenceCount
      return { tripTotal, recurringTripTotal }
    },
    async saveQuoteHandler(
      saveForLater = false,
      marketSelected = false,
      saveAndConvert = false,
      soldOut = false,
      isPendingConfirmation = false,
      isQuoteStopDateInPast = false
    ) {
      this.validationResults = {}
      this.formSubmitted = true
      this.hasCustomerFieldInputs = true
      if (this.$refs['customer-form'].validate() && !this.quote?.customer?.id) {
        await this.$refs['customer-form'].submitNewCustomerForm(true)
      }
      this.$nextTick(() => {
        if (!this.$refs['customer-form'].validate()) {
          this.validationResults['local-customer-form'] = {
            hasFailures: true,
          }
        } else {
          delete this.validationResults['local-customer-form']
        }
        this.showLeadSourceError = false
        this.showCheckoutPageError = false
        if (
          this.quote?.checkoutPage?.id === null ||
          this.quote?.leadSource?.id === null ||
          (this.quote.charterUpQuote && !this.quote.decisionDate)
        ) {
          if (this.quote?.checkoutPage?.id === null) {
            this.showCheckoutPageError = true
            this.validationResults['local-leadsource-checkoutpage-form'] = {
              hasFailures: true,
            }
          }
          if (this.quote?.leadSource?.id === null) {
            this.showLeadSourceError = true
            this.validationResults['local-leadsource-checkoutpage-form'] = {
              hasFailures: true,
            }
          }
          if (
            !this.$refs['quote-form-footer'].$refs[
              'quote-footer-form'
            ].validate() &&
            this.quote.charterUpQuote &&
            !this.quote.decisionDate
          ) {
            this.showDecisionDateError = true
            this.validationResults['quote-form-footer'] = {
              hasFailures: true,
            }
          }
        } else {
          delete this.validationResults['local-leadsource-checkoutpage-form']
        }
        if (!this.$refs['quote-form-configurations'].validate()) {
          this.validationResults['quote-form-configurations'] = {
            hasFailures: true,
          }
        }
        if (!this.$refs['quote-form-lead-follow-up'].validate()) {
          this.validationResults['lead-follow-up-detail'] = {
            hasFailures: true,
          }
        }
        if (this.quote?.isEnterprise && !this.quote?.accountExecutiveId) {
          this.showAccountExecutiveError = true
          this.validationResults['quote-form-enterprise-fields'] = {
            hasFailures: true,
          }
        }
        if (
          !this.$refs['quote-form-enterprise-fields'].validate() &&
          this.isAccountExecutiveAndOpportunityIdEnabled
        ) {
          this.validationResults['quote-form-enterprise-fields'] = {
            hasFailures: true,
          }
        }
        if (!this.$refs['quote-form-classifications'].validate()) {
          this.validationResults['quote-form-classifications'] = {
            hasFailures: true,
          }
        }
        if (
          !this.quote.trips.every(({ tripVehicleGroups }) =>
            tripVehicleGroups.some(
              ({ finalDropoffDate }) => finalDropoffDate?.date !== null
            )
          )
        ) {
          this.showAlert({
            message: `Final Dropoff Date is Required`,
            type: 'error',
          })
          EventBus.$emit('show-dropoff-date-required')
          return
        }
        if (
          !this.quote?.expirationDate &&
          this.quote?.checkoutPage?.label === 'CharterUP' &&
          marketSelected &&
          this.quote.charterUpQuote
        ) {
          this.showExpirationDateError = true
          this.validationResults['expiration-date'] = {
            hasFailures: true,
          }
        }
        this.validationKey = v4() // trigger validations for child components

        const quoteClone = deepCloneClass(this.quote.toObject())
        if (this.saveQuoteDebounce) {
          clearTimeout(this.saveQuoteDebounce)
        }
        this.saveQuoteDebounce = setTimeout(async () => {
          const hasFailures = Object.keys(this.validationResults).some((key) =>
            op(this.validationResults, `${key}/hasFailures`)
          )
          if (hasFailures) {
            this.lockSaveButton = false
            this.scrollToFirstFormError()
            return logger.warn(
              'Form validation results will not allow quote to be saved.'
            )
          } else {
            this.lockSaveButton = true
          }

          this.scrubQuote(quoteClone)
          const quoteCloneForAPI = await scrubQuoteForAPI(deepClone(quoteClone))
          this.isBASQuote = quoteCloneForAPI.trips.some(
            (trip) => trip?.paymentType?.id === 3
          )
          if (quoteCloneForAPI.trips && quoteCloneForAPI.trips.length > 0) {
            quoteCloneForAPI.trips.forEach(async (trip) => {
              if (!trip.tripContactId && trip.tripContact.email) {
                const addCustomerResponse = await customers.addCustomer({
                  payload: trip.tripContact,
                })
                if (addCustomerResponse.status === 200) {
                  await this.$refs['customer-form'].searchCustomerDetail(
                    'email',
                    trip.tripContact.email,
                    true
                  )
                }
              }
            })
            quoteCloneForAPI.trips.forEach((trip) => {
              trip.tripVehicleGroups = trip.tripVehicleGroups.filter(
                (group) => group.isActive
              )
              trip.stops = []
              trip.tripVehicles = []
              trip.vehicles = []
            })
          }

          // An existing quote
          if (
            this.isModeEdit ||
            this.isModeView ||
            this.showingMarketplaceViewForClient
          ) {
            const emailPreference = this.getEmailPreference(saveForLater)
            const params = {
              emailPreference,
              quoteId: this.quoteId,
              payload: quoteCloneForAPI,
            }
            try {
              const savedQuoteResults = await this.updateV2Quote(params)

              if (savedQuoteResults.status !== 200) {
                this.saveQuoteError = true
                this.saveQuoteErrorMessage =
                  'There was an error saving this quote.'
              } else {
                if (saveAndConvert) {
                  const approveQuotePayload = {
                    meta: { billing: {} },
                    isManual: true,
                    payment_method: 'check',
                  }
                  const params = {
                    quoteId: this.quoteId,
                    payload: approveQuotePayload,
                  }
                  const approveQuoteResponse = await this.convert(params).catch(
                    (e) => e.response
                  )
                  if (approveQuoteResponse.status !== 200) {
                    this.saveQuoteError = true
                    this.saveQuoteErrorMessage =
                      'There was an error saving this quote.'
                    return
                  }
                }

                if (this.showMarketplaceFeatures && !saveAndConvert) {
                  await this.resetQuoteForm(false, false)
                  if (!this.quote.isConfirmed) {
                    this.$router.push({
                      path: `/quotes/view/${this.quoteId}/confirmation`,
                    })
                  } else {
                    this.$router.push({
                      name: 'quotes.view',
                      params: { id: this.quote.quoteId, subRoute: 'bids' },
                    })
                  }
                } else {
                  this.$router.push({
                    name: 'quotes.new',
                    params: { id: this.quoteId, mode: 'view' },
                  })
                }

                this.showAlert({
                  message: `Quote ${this.quoteId} saved successfully.`,
                  type: 'success',
                  actionText: 'View Quote',
                  actionPath: `/quotes/view/${this.quoteId}`,
                  actionId: 'app-alert-button-action-quote-view',
                })
              }
            } catch (e) {
              this.lockSaveButton = false
              this.showAlert({
                message: e.response?.data?.message || `Quote failed to save.`,
                type: 'error',
              })
              throw e
            }
          } else {
            // A new quote
            if (isQuoteStopDateInPast) {
              this.newQuoteStopDatesInPastDialog = true
              this.saveForLater = saveForLater
              this.quoteClone = deepClone(quoteCloneForAPI)
              this.saveAndConvert = saveAndConvert
            } else {
              await this.createV2QuoteWrapper(
                saveForLater,
                quoteCloneForAPI,
                saveAndConvert
              )
            }
          }

          this.lockSaveButton = false
        }, 1000)
      })
    },
    getEmailPreference(saveForLater = false) {
      let emailPreference = 'email'
      if (saveForLater) {
        emailPreference = 'no-email'
      } else {
        if (this.quote.charterUpQuote) {
          emailPreference = 'charterup'
        }
      }
      return emailPreference
    },
    async createV2QuoteWrapper(saveForLater = false, quote, saveAndConvert) {
      const emailPreference = this.getEmailPreference(saveForLater)
      const params = {
        emailPreference,
        payload: quote,
      }
      try {
        const savedQuoteResults = await this.createV2Quote(params)
        if (savedQuoteResults.status !== 200) {
          this.saveQuoteError = true
          this.saveQuoteErrorMessage = 'There was an error saving this quote.'
          this.$store.dispatch('app/showAlert', {
            message: this.saveQuoteErrorMessage,
            type: 'error',
          })
          this.lockSaveButton = false
          return
        }

        const savedQuote = savedQuoteResults.data.quote
        if (saveAndConvert) {
          const approveQuotePayload = {
            meta: { billing: {} },
            isManual: true,
            payment_method: 'check',
          }
          const params = {
            quoteId: savedQuote.quoteId,
            payload: approveQuotePayload,
          }
          const approveQuoteResponse = await this.convert(params).catch(
            (e) => e.response
          )
          if (approveQuoteResponse.status !== 200) {
            const errorMessage = approveQuoteResponse?.data?.message
            return this.showAlert({
              type: 'error',
              message: `Error Approving Quote: ${errorMessage}`,
            })
          }
        }
        if (this.quote.contractId) {
          this.$router.push({
            name: 'contracts.view',
            params: { id: this.quote.contractId },
          })
        } else {
          if (this.showMarketplaceFeatures && !saveAndConvert) {
            await this.resetQuoteForm(true, false)
            this.$router.push({
              name: 'quotes.view',
              params: {
                id: savedQuote.quoteId,
                subRoute: 'bids',
              },
            })
          } else {
            this.$router.push({ name: 'quotes' })
          }
        }
        this.showAlert({
          message: `Quote ${savedQuote.quoteId} saved successfully.`,
          type: 'success',
          actionText: 'View Quote',
          actionPath: `/quotes/view/${savedQuote.quoteId}`,
          actionId: 'app-alert-button-action-quote-view',
        })
      } catch (e) {
        this.lockSaveButton = false
        this.showAlert({
          message: e.response?.data?.message || `Quote failed to save.`,
          type: 'error',
        })
        throw e
      }
    },
    hasVehicleConflict(tripIndex) {
      if (this.tripConflicts[tripIndex]) {
        return this.tripConflicts[tripIndex]?.hasConflict
      }
      return false
    },
    setLeadTemperatureType(leadTemperatureTypeId) {
      if (leadTemperatureTypeId) {
        this.setLeadTemperatureTypeIdModule({ leadTemperatureTypeId })
      }
    },
    setLeadFollowUpDate(leadFollowUpDate) {
      if (leadFollowUpDate) {
        this.setLeadFollowUpDateModule({ leadFollowUpDate })
      }
    },
    setQuoteNoteText(value) {
      let filteredNotes = this.quote?.quoteNotes?.[0]
      if (filteredNotes) {
        this.updateQuoteNoteModule({
          newNoteText: value,
        })
      } else {
        const noteObj = {
          note: value,
          createdBy: this?.currentUser?.userId,
          noteType: INTERNAL_NOTE_TYPE,
        }
        this.addQuoteNoteModule({ note: noteObj })
      }
    },
    setQuoteNoteHtml(value) {
      let filteredNotes = this.quote?.quoteNotes?.[0]
      if (filteredNotes) {
        this.updateQuoteNoteHtmlModule({
          html: value,
        })
      } else {
        const noteObj = {
          html: value,
          createdBy: this?.currentUser?.userId,
          noteType: INTERNAL_NOTE_TYPE,
        }
        this.addQuoteNoteModule({ note: noteObj })
      }
    },
    scrollToFirstFormError() {
      let input = null
      let options = { offset: 30 }

      if (
        this.validationResults?.['local-leadsource-checkoutpage-form']
          ?.hasFailures
      ) {
        input = this.$refs['leadsource-checkoutpage-form']
        options.offset = 100
      } else if (
        this.validationResults?.['quote-form-configurations']?.hasFailures
      ) {
        input = this.$refs['quote-form-configurations']
        options.offset = 100
      } else if (this.validationResults?.['local-customer-form']?.hasFailures) {
        input = this.$refs['customer-form']
      } else if (
        this.validationResults?.['lead-follow-up-detail']?.hasFailures
      ) {
        input = this.$refs['quote-form-lead-follow-up']
        options.offset = 50
      } else if (
        this.validationResults?.['quote-form-enterprise-fields']?.hasFailures
      ) {
        input = this.$refs['quote-form-enterprise-fields']
        options.offset = 50
      } else if (
        this.validationResults?.['quote-form-classifications']?.hasFailures
      ) {
        input = this.$refs['quote-form-classifications']
        options.offset = 50
      } else {
        let detailsError = false
        let paymentError = false
        let indexOfFirstTripWithError = -1
        for (const [key, value] of Object.entries(this.validationResults)) {
          if (
            (key.includes('detail') || key.includes('market')) &&
            value.hasFailures
          ) {
            detailsError = true
            indexOfFirstTripWithError =
              indexOfFirstTripWithError < 0
                ? key.split('-')[0]
                : indexOfFirstTripWithError
          } else if (key.includes('payment') && value.hasFailures) {
            paymentError = true
            indexOfFirstTripWithError =
              indexOfFirstTripWithError < 0
                ? key.split('-')[0]
                : indexOfFirstTripWithError
          }
        }
        this.selectedTrip =
          indexOfFirstTripWithError >= 0
            ? indexOfFirstTripWithError
            : this.selectedTrip

        if (detailsError || paymentError) {
          input = this.$refs['trip-details-toggle']
          options.offset = 85
        }
      }

      if (input) {
        this.$vuetify.goTo(input.$el, options)
      }
    },
    toggleShowLastQuote(payload) {
      this.showLastQuote = !this.showLastQuote
      this.showPastQuotes = false
      if (payload) {
        this.lastQuoteId = payload
      }
    },
    async scrubQuoteFromAPI(quote, scrubRecurrenceIds = true) {
      if (quote.customer && quote.customer.customerId) {
        this.isCustomerSelected = true
        quote.customer.id = quote.customer.customerId
        if (quote.customer.customerAccount) {
          this.customerAccount = quote.customer.customerAccount
        }
      }
      if (quote.leadSourceType) {
        quote.leadSource = quote.leadSourceType
        quote.leadSource.displayName = quote.leadSource.label
        quote.leadSource.leadSourceId = quote.leadSource.id
        this.leadSourceSearchTerm = quote.leadSource.label
      }
      if (quote.checkoutPage) {
        quote.checkoutPage.id = quote.checkoutPage.checkoutPageId
        this.checkoutPageSearchTerm = quote.checkoutPage.label
      } else {
        quote.checkoutPage = CHARTERUP_CHECKOUT_PAGE
      }

      if (quote.expirationDate && quote.expirationTimezone) {
        quote.expirationDate = DateTime.fromISO(quote.expirationDate)
          .setZone(quote.expirationTimezone)
          .toISO({ includeOffset: false })
      }
      quote.trips = this.scrubTripsFromAPI(
        quote.trips,
        quote.charterUpQuote,
        scrubRecurrenceIds
      )
      return quote
    },
    scrubTripsFromAPI(
      trips,
      isMarketplaceQuote = false,
      scrubRecurrenceIds = true
    ) {
      trips.forEach((trip) => {
        trip.requiredVehicles = trip.vehicles
        trip.paymentType = { id: trip.paymentTypeId }
        trip.stops.sort((a, b) => (a.orderIndex > b.orderIndex ? 0 : -1))
        for (const stop of trip.stops) {
          if (stop.address) {
            stop.address.addressName = stop.address.name
          }

          if (stop.pickupDatetime) {
            let pickupTime = DateTime.fromISO(stop.pickupDatetime, {
              zone: stop.address.timeZone,
            })
              .toISO({ suppressMilliseconds: true })
              .split('T')[1]
            const pickupTimeParts = pickupTime.split(':')
            pickupTime = `${pickupTimeParts[0]}:${pickupTimeParts[1]}`

            stop.pickupDate = DateTime.fromISO(stop.pickupDatetime, {
              zone: stop.address.timeZone,
            }).toISODate()
            stop.pickupTime = pickupTime
          }

          if (stop.spotTime?.spotTime) {
            stop.spotTime.spotTime = DateTime.fromISO(stop.spotTime.spotTime, {
              zone: stop.address.timeZone,
            }).toISO({ includeOffset: false })
          }

          if (stop.dropoffDatetime) {
            let dropoffTime = DateTime.fromISO(stop.dropoffDatetime, {
              zone: stop.address.timeZone,
            })
              .toISO({ suppressMilliseconds: true })
              .split('T')[1]
            const dropoffTimeParts = dropoffTime.split(':')
            dropoffTime = `${dropoffTimeParts[0]}:${dropoffTimeParts[1]}`

            stop.dropoffDate = DateTime.fromISO(stop.dropoffDatetime, {
              zone: stop.address.timeZone,
            }).toISODate()
            stop.dropoffTime = dropoffTime
          }
        }
        for (const recurrence of trip.recurrences) {
          if (scrubRecurrenceIds) {
            recurrence.recurrenceId = null
          }
          recurrence.recurrenceExclusions.forEach((recurrenceExclusion) => {
            recurrenceExclusion.recurrenceExclusionId = null
          })
        }
        for (const paymentMethod of trip.paymentMethods) {
          if (paymentMethod.isAllowed === 1) {
            paymentMethod.isAllowed = true
          } else {
            paymentMethod.isAllowed = false
          }
        }
        const charges = trip.charges || []
        const processingFeeCharge = charges.find(
          (c) =>
            c?.chargeType?.key === 'processing_fees' || c?.chargeType?.id === 4
        )

        const processingFeeChargeAmount = processingFeeCharge
          ? processingFeeCharge.amount
          : 0

        const marketplaceDiscountCharge = isMarketplaceQuote
          ? charges.find(
              (charge) =>
                charge?.chargeType?.key === 'discount' ||
                charge?.chargeType?.id === 15
            )
          : null

        const marketplaceMarkupCharge = isMarketplaceQuote
          ? charges.find(
              (charge) =>
                charge?.chargeType?.key === 'markup' ||
                charge?.chargeType?.id === 18
            )
          : null

        const marketplaceDiscountChargeAmount = marketplaceDiscountCharge
          ? marketplaceDiscountCharge.amount
          : 0

        const marketplaceMarkupChargeAmount = marketplaceMarkupCharge
          ? marketplaceMarkupCharge.amount
          : 0

        const subtotal =
          trip.total -
          processingFeeChargeAmount -
          marketplaceDiscountChargeAmount -
          marketplaceMarkupChargeAmount

        if (processingFeeCharge) {
          trip.originalProcessingFeeCharge = processingFeeCharge
          trip.processingFeeCharge = processingFeeChargeAmount
          trip.processingFeePercentage = Math.round(
            (parseFloat(processingFeeChargeAmount) / parseFloat(subtotal)) *
              100.0
          )
          trip.charges = trip.charges.filter(
            (c) =>
              c?.chargeType?.key !== 'processing_fees' ||
              c?.chargeType?.id !== 4
          )
        } else {
          trip.processingFeePercentage = 0
        }
        if (marketplaceDiscountCharge) {
          trip.originalMarketplaceDiscountCharge = marketplaceDiscountCharge
          trip.marketplaceDiscountCharge = marketplaceDiscountChargeAmount
          trip.marketplaceDiscountPercent = Math.round(
            (marketplaceDiscountChargeAmount /
              (subtotal + processingFeeChargeAmount)) *
              -100
          )
          trip.charges = trip.charges.filter(
            (c) => c?.chargeType?.key !== 'discount' || c?.chargeType?.id !== 15
          )
        } else {
          trip.marketplaceDiscountPercent = 0
        }
        if (marketplaceMarkupCharge) {
          trip.originalMarketplaceAmountCharge = marketplaceMarkupCharge
          trip.marketplaceMarkupCharge = marketplaceMarkupChargeAmount
          trip.marketplaceMarkupPercent = Math.round(
            (marketplaceMarkupChargeAmount /
              (subtotal + processingFeeChargeAmount)) *
              100
          )
          trip.charges = trip.charges.filter(
            (c) => c?.chargeType?.key !== 'markup' || c?.chargeType?.id !== 18
          )
        } else {
          trip.marketplaceMarkupPercent = 0
        }
        if (trip.dueDate) {
          trip.dueDate = DateTime.fromISO(trip.dueDate, {
            zone: 'utc',
          }).toISODate()
        }
        if (trip.tripContact) {
          trip.istripContactSelected = true
        } else {
          trip.tripContact = {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
          }
          trip.istripContactSelected = false
        }
      })
      return trips
    },
    async searchLeadSourceList(value) {
      if (typeof value !== 'string' || value.length === 0) {
        return
      }

      const filterObject = {
        column: {
          _t_id: 'lead_source_search_id',
          prop: 'displayName',
          filterType: 'contains',
          filterAsIs: true,
        },
        value,
      }
      const leadSourceFilter = filter()
      const parentFilter = leadSourceFilter.createParent('and')
      leadSourceFilter.add(parentFilter, filterObject)
      if (this.leadSourceDebounce) {
        clearTimeout(this.leadSourceDebounce)
      }
      this.leadSourceDebounce = setTimeout(async () => {
        const params = {
          filters: leadSourceFilter.asQueryParams(),
          pageSize: 5,
        }
        const matchedLeadSources = await leadSources.getLeadSources(params)
        const suggestedLeadSources = matchedLeadSources?.data?.resultList || []
        this.suggestedLeadSources = suggestedLeadSources.filter(
          (suggestedLeadSource) => suggestedLeadSource.internalName
        )
      }, 500)
    },
    selectLeadSourceFromList(leadSource) {
      if (!leadSource) {
        return
      }
      if (!leadSource.internalName) {
        return false
      }
      const { company } = this.currentUser
      const leadSourceObj = {
        id: leadSource.leadSourceId,
        label: leadSource.displayName,
        key: leadSource.internalName,
        partnerCompanyId: leadSource.partnerCompanyId,
        company: {
          id: company.companyId,
        },
      }
      this.setLeadSourceModule({ leadSource: leadSourceObj })
      this.isLeadSourceSelected = true
    },
    selectContractFromList(contract) {
      this.contract = contract
      this.setContractIdModule({
        contractId: this.contract?.contractId || this.contract?.quoteId || null,
      })
    },
    async searchCheckoutPagesList(value) {
      const filterObject = {
        column: {
          _t_id: 'checkout_pages_search_id',
          prop: 'name',
          filterType: 'contains',
        },
      }
      filterObject.value = value
      if (typeof value === 'string') {
        return
      }
      const checkoutPageFilter = filter()
      const parentFilter = checkoutPageFilter.createParent('and')
      checkoutPageFilter.add(parentFilter, filterObject)
      if (this.checkoutPageDebounce) {
        clearTimeout(this.checkoutPageDebounce)
      }
      this.checkoutPageDebounce = setTimeout(async () => {
        const params = {
          filters: checkoutPageFilter.asQueryParams(),
          pageSize: 5,
        }
        const matchedCheckoutPages = await checkoutPages.getCheckoutPages(
          params
        )
        this.suggestedCheckoutPages = [].concat(
          matchedCheckoutPages?.data?.resultList || [],
          CHARTERUP_CHECKOUT_PAGE
        )
      }, 500)
    },
    async searchContractsList(value) {
      const filterObject = {
        column: {
          _t_id: 'checkout_pages_search_id',
          prop: 'contractName',
          filterType: 'contains',
        },
      }
      filterObject.value = value
      if (typeof value === 'string') {
        if (value.length === 0) {
          return
        }
      } else {
        return
      }
      const contractsFilter = filter()
      const parentFilter = contractsFilter.createParent('and')
      contractsFilter.add(parentFilter, filterObject)
      const params = {
        filters: contractsFilter.asQueryParams(),
        pageSize: 5,
      }
      const matchedContractQuotes = await this.$store
        .dispatch('quotes/quotesListView', params)
        .catch((error) => {
          logger.error(error)
          this.tableProps.loading = false

          return false
        })
      this.suggestedContracts = [].concat(
        matchedContractQuotes?.data?.resultList || []
      )
    },

    selectCheckoutPageFromList(checkoutPage) {
      if (!checkoutPage) {
        return
      }

      const checkoutPageObj = {
        id: checkoutPage.companyCheckoutPageId,
        label: checkoutPage.name,
        key: checkoutPage.internalName,
      }
      this.setCheckoutPageModule({ checkoutPage: checkoutPageObj })
      this.setQuotePricingMethodModule({ pricingMethod: null })

      this.searchCheckoutPagesList('')
      this.isCheckoutPageSelected = true
      this.getEstimations()
    },
    selectQuotePricingMethod(pricingMethod) {
      this.setQuotePricingMethodModule({ pricingMethod })
    },
    resetLeadSource() {
      const leadSourceObj = {
        id: null,
        lable: null,
        key: null,
      }
      this.setLeadSourceModule({ leadSource: leadSourceObj })
      this.isLeadSourceSelected = false
    },
    resetCheckoutPage() {
      const checkoutPageObj = {
        id: null,
        label: null,
        key: null,
      }
      this.setCheckoutPageModule({ checkoutPage: checkoutPageObj })
      this.setQuotePricingMethodModule({ pricingMethod: null })

      this.searchCheckoutPagesList('')
      this.isCheckoutPageSelected = false
    },
    captureValidationResults(validationResults) {
      const { tripIndex, tripModule, hasFailures = false } = validationResults
      this.validationResults[tripIndex] = { hasFailures }
      this.validationResults[`${tripIndex}-${tripModule}`] = { hasFailures }
      this.$forceUpdate()
    },
    subTabClass(tripIndex, tripModule, subTab) {
      const validationResults = this.validationResults
      if (
        validationResults[`${tripIndex}-${tripModule}`] &&
        validationResults[`${tripIndex}-${tripModule}`].hasFailures === true
      ) {
        if (this.toggleSubTabs === subTab) {
          return 'error'
        }
        return 'error-non-selected'
      }
      if (
        validationResults[`${tripIndex}-${tripModule}-method`] &&
        validationResults[`${tripIndex}-${tripModule}-method`].hasFailures ===
          true
      ) {
        if (this.toggleSubTabs === subTab) {
          return 'error'
        }
        return 'error-non-selected'
      }
      if (this.toggleSubTabs === subTab) {
        return 'primary'
      }
      return ''
    },
    scrubQuote(quoteClone, addTripId = false) {
      if (!quoteClone) {
        quoteClone = deepClone(this.quote)
      }
      const { customer, company, trips = [] } = quoteClone
      const phone = customer?.phone || ''
      customer.phone = phone.replace(/[^0-9]/g, '')
      // To link the data correctly certain fields must be filled in
      trips.forEach((trip, tripIndex) => {
        if (addTripId) {
          trip.tripId = tripIndex
        }
        trip.customer = customer
        trip.company = company
        if (trip.dueDate) {
          if (typeof trip.dueDate === 'string') {
            trip.dueDate = DateTime.fromFormat(trip.dueDate, 'yyyy-MM-dd', {
              zone: 'America/New_York',
            })
              .toUTC()
              .toISO()
          } else {
            trip.dueDate = trip.dueDate.toUTC().toISO()
          }
        }
        const { tripType = {}, requiredVehicles = [] } = trip
        const foundTripType = this.tripTypes.find((t) => t.id === tripType?.id)
        if (foundTripType) {
          trip.tripTypeKey = foundTripType.key
        }
        requiredVehicles.forEach((requiredVehicle) => {
          const { vehicleType = {} } = requiredVehicle
          const foundVehicleType = this.vehicleTypes.find(
            (v) => v.id === vehicleType?.id
          )
          if (foundVehicleType) {
            requiredVehicle.vehicleTypeKey = foundVehicleType.key
          }
        })
        const { stops = [], charges = [], processingFeeCharge = 0 } = trip
        const marketplaceDiscountCharge = trip?.marketplaceDiscountCharge || 0
        const marketplaceMarkupCharge = trip?.marketplaceMarkupCharge || 0
        if (processingFeeCharge > 0) {
          if (trip.originalProcessingFeeCharge) {
            trip.originalProcessingFeeCharge.amount = processingFeeCharge
            charges.push(trip.originalProcessingFeeCharge)
          } else {
            charges.push({
              amount: processingFeeCharge,
              chargeType: {
                id: 4,
                key: 'processing_fees',
                label: 'Processing Fees',
              },
            })
          }
        }
        if (marketplaceMarkupCharge > 0) {
          if (trip.originalMarketplaceAmountCharge) {
            trip.originalMarketplaceAmountCharge.amount = marketplaceMarkupCharge
            charges.push(trip.originalMarketplaceAmountCharge)
          } else {
            charges.push({
              amount: marketplaceMarkupCharge,
              chargeType: {
                id: 18,
                key: 'markup',
                label: 'Markup',
              },
            })
          }
        }
        if (marketplaceDiscountCharge * -1 > 0) {
          if (trip.originalMarketplaceDiscountCharge) {
            trip.originalMarketplaceDiscountCharge.amount = marketplaceDiscountCharge
            charges.push(trip.originalMarketplaceDiscountCharge)
          } else {
            charges.push({
              amount: marketplaceDiscountCharge,
              chargeType: {
                id: 15,
                key: 'discount',
                label: 'Discount',
              },
            })
          }
        }
        stops.forEach((stop, stopIndex) => {
          stop.orderIndex = stopIndex
          const timeZone = stop?.address?.timeZone
          if (stop.pickupDate) {
            stop.pickupDatetime = getDatetimeFromDateAndTimeStrings(
              stop.pickupDate,
              stop.pickupTime,
              timeZone
            )
          }
          if (stop.dropoffDate) {
            stop.dropoffDatetime = getDatetimeFromDateAndTimeStrings(
              stop.dropoffDate,
              stop.dropoffTime,
              timeZone
            )
          }
        })
        trip.startDate = stops?.[0]?.pickupDatetime
      })
      if (quoteClone.leadFollowUpDate) {
        quoteClone.leadFollowUpDate = DateTime.fromISO(
          quoteClone.leadFollowUpDate
        )
          .setZone(this.currentUser?.timeZone || 'America/New_York')
          .toISO()
      }
    },
    duplicateTrips(trips, marketplace = false) {
      const clonedTrips = deepClone(trips)
      this.scrubTripsFromAPI(clonedTrips, marketplace)
      clonedTrips.forEach(this.duplicateTrip)
      this.trips = clonedTrips
      this.setTripsModule(this.trips)
      this.showPastQuotes = false
      this.showLastQuote = false
      this.$forceUpdate()
      this.$nextTick(() => {
        this.getEstimations()
      })
    },
    duplicateTripFromPrevious(trip) {
      const clonedTrip = deepCloneClass(trip)
      this.addTripModule({ trip: clonedTrip })
      this.$nextTick(() => {
        this.getEstimations()
      })
    },
    duplicateTrip(trip) {
      delete trip.id
      delete trip.tripId
      delete trip.quotes
      delete trip.createdOn
      delete trip.reservations
      trip.tripAmenities = []
      trip.recurrences = []
      trip.charges = trip.charges.filter(
        (charge) => charge.chargeType.id !== ChargeTypeId.Amenities
      )
      trip.openForBid = false
      trip.calendarDays = 1
      const { charges = [], stops = [], rates = [], paymentMethods = [] } = trip
      let { vehicles = [], requiredVehicles = [] } = trip
      if (
        (!vehicles || vehicles.length === 0) &&
        requiredVehicles &&
        requiredVehicles.length > 0
      ) {
        vehicles = requiredVehicles
      }
      const extractTimeAndDate = (dateTime, timeZone) => {
        const hours = DateTime.fromISO(dateTime, { zone: timeZone }).toFormat(
          'HH'
        )
        const minutes = DateTime.fromISO(dateTime, { zone: timeZone }).toFormat(
          'mm'
        )
        const time = `${hours}:${minutes}`

        const date = DateTime.fromISO(dateTime, { zone: timeZone }).toISODate()
        return { date, time }
      }
      if (trip.dueDate) {
        trip.dueDate = DateTime.fromISO(trip.dueDate).toISODate()
      }
      trip.paymentType = {
        id: trip.paymentTypeId || trip?.paymentType?.id,
      }

      const tripNotes = (trip.tripNotes || []).map((note) => {
        delete note.noteId
        return note
      })
      trip.tripNotes = tripNotes

      stops.forEach((stop) => {
        delete stop.stopId
        delete stop.id
        delete stop.trip
        delete stop.createdOn
        stop.isPickupEstimated = false
        stop.isDropoffEstimated = false
        const { address } = stop
        const { timeZone } = address || {}
        if (stop.pickupDatetime) {
          const { date, time } = extractTimeAndDate(
            stop.pickupDatetime,
            timeZone
          )
          stop.pickupDate = date
          stop.pickupTime = time
        }
        if (stop.dropoffDatetime) {
          const { date, time } = extractTimeAndDate(
            stop.dropoffDatetime,
            timeZone
          )
          stop.dropoffDate = date
          stop.dropoffTime = time
        }
        if (stop.address && (stop.address.id || stop.address.addressId)) {
          delete stop.address.id
          delete stop.address.addressId
          stop.address.addressName = stop.address.name
        }
      })
      const clearId = (list, idProp) => {
        const defaultId = 'id'
        list.forEach((item) => {
          delete item[idProp || defaultId]
        })
      }

      if (trip.originalProcessingFeeCharge) {
        delete trip.originalProcessingFeeCharge.tripChargeId
        delete trip.originalProcessingFeeCharge.tripId
      }
      if (trip.originalMarketplaceDiscountCharge) {
        delete trip.originalMarketplaceDiscountCharge.tripChargeId
        delete trip.originalMarketplaceDiscountCharge.tripId
      }

      clearId(rates, 'tripRateId')
      clearId(charges, 'tripChargeId')
      clearId(vehicles, 'tripVehicleId')
      clearId(paymentMethods, 'tripPaymentMethodId')

      trip.tripVehicleGroups = this.duplicateTripVehicleGroups(
        trip.tripVehicleGroups,
        trip.stops
      )
      trip.requiredVehicles = vehicles
      return trip
    },
    duplicateTripVehicleGroups(tripVehicleGroups, stops) {
      for (let tvg of tripVehicleGroups) {
        const oldHash = deepClone(tvg.tripVehicleGroupId)
        const newHash = v4()
        delete tvg.tripVehicleGroupId
        tvg.tripVehicleGroupHash = newHash
        tvg.tripVehicleGroupStops.map((stop) => {
          delete stop.stopId
          stop.tripVehicleGroupId = newHash
          return stop
        })
        tvg.tripVehicles.map((vehicle) => {
          delete vehicle.tripVehicleId
          vehicle.tripVehicleGroupId = newHash
        })
        stops
          .filter((stop) => stop.tripVehicleGroupId === oldHash)
          .forEach((stop) => {
            stop.tripVehicleGroupId = newHash
          })
      }
      return tripVehicleGroups
    },
    getTimeZoneForTrip(trip) {
      return (
        trip?.stops?.[0]?.address?.timeZone ||
        trip?.tripAddresses?.[0]?.address?.timeZone
      )
    },
    async changeCurrencyType(currencyType) {
      this.setCurrencTypeModule({ currencyType })
      if (currencyType !== 'USD') {
        this.showAlternativeCurrency = true
        const exchangeRateData = await exchangeRate
          .getExchangeRate()
          .catch((e) => e)
        this.exchangeRate = exchangeRateData?.data?.rates?.[currencyType]
      }
    },
    showDialog(type) {
      if (type === 'approveQuote' && this.isBASQuote) {
        type = 'approveBASQuote'
      }
      this.dialogType = type

      if (type === 'approveQuote') {
        return
      }

      this.actionsDialog = true
    },
    onCloseDialog() {
      this.actionsDialog = false
      this.sidebarDialog = false
    },
    quoteApproved() {
      this.actionsDialog = false
      if (this.quoteId) {
        this.$router.push({
          name: 'quotes.new',
          params: {
            id: this.quoteId,
            mode: 'view',
          },
        })
        this.$forceUpdate()
      } else if (this.quote.contractId) {
        this.$router.push({
          name: 'contracts.view',
          params: { id: this.quote.contractId },
        })
      } else {
        this.$router.push({ name: 'quotes' })
      }
    },
    async approveBASQuote() {
      this.actionsDialog = false
      this.actionsMenuLoading = true
      const approveQuotePayload = {
        meta: { billing: {} },
        manual: true,
        payment_method: 'check',
      }
      const params = {
        quoteId: this.quoteId,
        payload: approveQuotePayload,
      }
      const approveQuoteResponse = await this.convert(params).catch(
        (e) => e.response
      )
      if (approveQuoteResponse.status !== 200) {
        const errorMessage = approveQuoteResponse?.data?.message
        this.actionsMenuLoading = false
        return this.showAlert({
          type: 'error',
          message: `Error Approving Quote: ${errorMessage}`,
        })
      } else {
        if (this.quote.quoteId) {
          if (this.isModeView) {
            await this.resetQuoteForm(false, false)
            this.actionsMenuLoading = false
            return
          } else {
            this.$router.push({
              name: 'quotes.new',
              params: {
                id: this.quote.quoteId,
                mode: 'view',
              },
            })
          }
        } else if (this.quote.contractId) {
          this.$router.push({
            name: 'contracts.view',
            params: { id: this.quote.contractId },
          })
        } else {
          this.$router.push({ name: 'quotes' })
        }
        this.actionsMenuLoading = false
        this.showAlert({
          message: 'Successfully approved quote',
        })
      }
    },
    checkoutPageUrl() {
      const { checkoutPage = {}, hash } = this.quote || {}
      if (
        !checkoutPage ||
        checkoutPage?.checkoutPageId === CHARTERUP_CHECKOUT_PAGE.checkoutPageId
      ) {
        return `https://${environmentPrefix()}.charterup.com/guest-checkout/${hash}`
      }
      const domain = checkoutPage?.domain || {}
      return `${domain}/checkout/${hash}`
    },
    viewCheckoutPage() {
      window.open(this.checkoutPageUrl(), '_blank')
    },
    editMode() {
      this.$router.push({
        name: 'quotes.new',
        params: { id: this.quote.quoteId, mode: 'edit' },
      })
      this.getEstimations()
    },
    setCurrentTripIndex(tripIndex) {
      this.setCurrentTripIndexModule({ currentTripIndex: tripIndex })
    },
    setRouteName(tripIndex, routeName) {
      this.setRouteNameModule({ tripIndex, routeName })
    },
    clearRouteName(tripIndex) {
      this.setRouteNameModule({ tripIndex, routeName: null })
    },
    changeClassification(typeKey, classificationId) {
      if (typeKey === 'product') {
        this.setProductClassificationIdModule({
          productClassificationId: classificationId,
        })
      } else if (typeKey === 'sourcing-team') {
        this.setSourcingTeamClassificationIdModule({
          sourcingTeamClassificationId: classificationId,
        })
      } else if (typeKey === 'support-team') {
        this.setSupportTeamClassificationIdModule({
          supportTeamClassificationId: classificationId,
        })
      }
    },
    async initializeSplitTrip() {
      this.confirmSplitTripLoading = true
      this.setShouldSplitTrip(false)
      let quoteClone = deepClone(this.quote)
      this.scrubQuote(quoteClone)
      quoteClone = scrubQuoteForAPI(quoteClone, true)
      const splitTripQuote = await this.getSplitTrips(quoteClone).catch(
        () => false
      )
      if (Array.isArray(splitTripQuote?.data?.trips)) {
        this.duplicateTrips(splitTripQuote?.data?.trips, true)
      }
      this.setTripsRefreshPricingModule({ value: true })
      this.confirmSplitTripLoading = false
      this.confirmSplitTripDialog = false
    },
    async loadCustomerAccountDefaults(customerId) {
      const defaultsResponse = await customers.getCustomerAccountDefaultsByCustomer(
        customerId
      )
      this.customerAccountDefaults = defaultsResponse.data
      this.setCustomerAccountDefaultValues()
    },
    async loadCustomerServiceTier(customerId) {
      const serviceTierResponse = await customers.getCustomerServiceTier(
        customerId
      )
      if (serviceTierResponse?.data) {
        this.updateQuoteTier(serviceTierResponse.data)
      }
    },
    setCustomerAccountDefaultValues() {
      if (!this.customerAccountDefaults) {
        return
      }
      if (this.customerAccountDefaults.pricingMethodKey) {
        const existingPricingMethod = this.availablePricingMethods.find(
          (pm) => pm.key === this.customerAccountDefaults.pricingMethodKey
        )
        if (existingPricingMethod) {
          this.setQuotePricingMethodModule({
            pricingMethod: this.customerAccountDefaults.pricingMethodKey,
          })
        }
      }
      if (this.customerAccountDefaults.productClassification?.id) {
        this.setProductClassificationIdModule({
          productClassificationId: this.customerAccountDefaults
            .productClassification.id,
        })
      }
      if (this.customerAccountDefaults.sourcingTeamClassification?.id) {
        this.setSourcingTeamClassificationIdModule({
          sourcingTeamClassificationId: this.customerAccountDefaults
            .sourcingTeamClassification.id,
        })
      }
      if (this.customerAccountDefaults.supportTeamClassification?.id) {
        this.setSupportTeamClassificationIdModule({
          supportTeamClassificationId: this.customerAccountDefaults
            .supportTeamClassification.id,
        })
      }
      if (this.customerAccountDefaults?.minQuality !== undefined) {
        this.setMinQualityModule({
          minQuality: this.customerAccountDefaults.minQuality,
        })
      }
      if (this.customerAccountDefaults.accountExecutive?.id) {
        this.setAccountExecutiveModule({
          accountExecutiveId: this.customerAccountDefaults.accountExecutive.id,
        })
      }

      const existingPaymentTypeId = this.paymentTypes?.find(
        (type) => type.key === this.customerAccountDefaults.paymentType?.key
      )?.id

      for (const [tripIndex, trip] of this.quote?.trips?.entries() || []) {
        const existingMethodIndex = trip?.paymentMethods?.findIndex(
          (method) =>
            method?.paymentMethodType?.key ===
            this.customerAccountDefaults.paymentMethodType?.key
        )
        if (existingMethodIndex) {
          for (const [paymentIndex] of trip?.paymentMethods?.entries() || []) {
            this.setPaymentMethodAllowedModule({
              paymentMethodIndex: paymentIndex,
              value: false,
              tripIndex,
            })
          }
          this.setPaymentMethodAllowedModule({
            paymentMethodIndex: existingMethodIndex,
            value: true,
            tripIndex,
          })
        }
        if (
          this.customerAccountDefaults.discountPercent &&
          this.quote.pricingMethod !== 'single_price'
        ) {
          this.setMarketplaceDiscountPercentModule({
            marketplaceDiscountPercent: this.customerAccountDefaults
              .discountPercent,
            tripIndex,
          })
        }
        if (
          this.customerAccountDefaults.markupPercent &&
          this.quote.pricingMethod !== 'single_price'
        ) {
          this.setMarketplaceMarkupPercentModule({
            marketplaceMarkupPercent: this.customerAccountDefaults
              .markupPercent,
            tripIndex,
          })
        }
        if (existingPaymentTypeId) {
          this.setPaymentTypeIdModule({
            paymentTypeId: existingPaymentTypeId,
            tripIndex,
          })
        }
        // check default down payment percent is not null and between 0 and 100 included
        if (
          this.customerAccountDefaults.downPaymentPercent != null &&
          this.customerAccountDefaults.downPaymentPercent >= 0 &&
          this.customerAccountDefaults.downPaymentPercent <= 100
        ) {
          this.setDepositPercentageModule({
            depositPercentage: this.customerAccountDefaults.downPaymentPercent,
            tripIndex,
          })
        }
        if (this.customerAccountDefaults.paymentTermsDays >= 0) {
          if (this.earliestPickupDateTime(trip) !== null) {
            const dueDate = this.earliestPickupDateTime(trip).plus({
              days: this.customerAccountDefaults.paymentTermsDays,
            })
            this.setDueDateModule({ dueDate, tripIndex })
          }
        }
      }
    },
    earliestPickupDateTime(trip) {
      const stopToPickupTime = function (stop) {
        const pickupDate = stop.pickupDate
        const pickupTime = stop.pickupTime
        const timeZone = stop.address.timeZone

        return DateTime.fromISO(`${pickupDate}T${pickupTime}`, {
          zone: timeZone,
        })
      }
      const pickupDateTimes = trip.stops
        .filter((s) => !!s.pickupDate)
        .map((s) => stopToPickupTime(s))
      let earliestPickupDateTime = null
      for (const pickupDateTime of pickupDateTimes) {
        if (
          earliestPickupDateTime === null ||
          pickupDateTime.toMillis() < earliestPickupDateTime.toMillis()
        ) {
          earliestPickupDateTime = pickupDateTime
        }
      }
      return earliestPickupDateTime
    },
  },
}
</script>

<style lang="scss" scoped>
.account-executive-autocomplete ::v-deep .v-input__control {
  min-height: 44px;
}
.lead-source-button {
  margin: 0;
  align-self: end;
  color: $primary;
  cursor: pointer;
}

.why {
  margin-top: -21px;
}

.not {
  margin-top: -25px;

  h1 {
    margin-left: 33px;
  }
}

.deleted-warning {
  color: $red;
  font-size: 14px;
  padding: 10px;
  border: 1px solid $red;
  border-radius: 5px;
  background-color: $error-pale;
  margin-top: 18px;
}

@media only screen and (max-width: 1140px) {
  .why {
    margin-top: -25px;

    h1 {
      margin-left: 33px;
    }
  }
}

.quote-trip-tab {
  position: relative;

  &::v-deep .v-tabs__item {
    padding-right: 10px;
    padding-left: 10px;
    &:not(.v-tabs__item--active) {
      .icon-button-close {
        margin-bottom: 2px !important;
      }
    }
  }

  &::v-deep.failed-validation {
    .v-tabs__item {
      font-weight: bold;
      color: $error;
    }
  }

  .icon-button-close {
    width: 8px;
    height: 8px;
    visibility: hidden;

    &::v-deep .v-icon {
      font-size: 16px;
    }
  }

  &:hover .icon-button-close {
    visibility: visible;
  }

  .active-error {
    color: $error;
  }
}

.new-customer-container {
  align-content: center;
  justify-content: center;
}

.quote-totals {
  background-color: $white;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba($black-base, 0.2),
    0 3px 10px 0 rgba($black-base, 0.19);

  max-width: 1600px;
  margin: 20px auto;
}

.results {
  margin-top: 40px;
  padding: 0 30px 10px;
}

.info-padded {
  padding-left: 12px;
}

.error-message {
  margin: 10px;
  font-size: 12px;
  color: $error !important;
}

.error-message-user {
  margin: 10px;
  padding-top: 20px;
  font-size: 14px;
  color: $error !important;
  padding-left: 12px;
}

.error-message-bottom {
  margin-right: 20px;
  font-size: 12px;
  color: $error !important;
}

.button-group {
  margin-top: 20px;

  :first-child {
    margin: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  :last-child {
    margin: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.error-non-selected {
  color: $error !important;
}

.notes-button {
  padding-top: 20px;
  margin-right: -8px;
}

.tab-icon-close {
  margin-top: -2px;
}

.quote-totals-save-actions {
  margin-top: -6px;
  margin-right: 8px;
}

.notes-actions {
  padding-right: 8px;
}

::v-deep.v-card__title {
  color: $white;
  background-color: $blue !important;
}

.v-card {
  border-radius: 10px;
}

.past-quotes-label {
  font-size: 23px;
}

.free-quote-warning {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: $gray-light;
  border-radius: 6px;
}

.action-button {
  padding: 5px 15px;
}

.form-options {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .decision-date {
    max-width: 156px;
    max-height: 45px;
    margin-top: 6px;
    margin-right: 8px;
  }

  .switch-right.v-input {
    flex: 0 1 auto;
    justify-content: flex-end;
    padding-right: 1em;

    ::v-deep &__slot {
      justify-content: flex-end;
      padding-right: 1em;
    }
  }
}

.timestamp {
  font-size: 12px;

  .label {
    font-weight: bold;
  }
}

.account-executive-autocomplete ::v-deep .v-input__control {
  min-height: 44px;
}

.checkout {
  align-items: center;
  padding-top: 15px;
  margin-left: 20px;

  .tooltip-position {
    margin-bottom: 18px;
    margin-left: 5px;

    i {
      padding-bottom: 1px;
      font-size: 16px;
      color: $gray-mid-light !important;
    }
  }
}

.pdf-checkbox::v-deep .v-input__control .v-input__slot {
  background-color: transparent !important;
}

.add-quote-container {
  margin-top: 10px;
}

.trip-detail {
  margin: 0;
}

.lead-temp-follow-up {
  background-color: $white;
  border-radius: 10px;
  padding: 20px 25px;
  max-width: 1600px;
  margin: 20px auto;
}
.sheet {
  max-width: 1600px;
  margin: 20px auto;
}

.trip-details-button {
  border-radius: 5px !important;
  .v-btn {
    border: 1px solid $gray-light;
    border-radius: 0 !important;
  }
  :first-child {
    border-radius: 5px 0 0 5px !important;
  }
  :last-child {
    border-radius: 0 5px 5px 0 !important;
  }
}

.quote-email-customization {
  max-width: 1600px;
  margin: 25px auto;
}

::v-deep .quote-email-customization {
  box-shadow: none;

  > li.v-expansion-panel__container {
    border-radius: 10px;
  }
}

.top-section {
  margin: 0 auto 20px;
}

.lead-overview {
  border: 1px solid $gray-light;

  &-column {
    padding: 10px;

    strong,
    span {
      display: inline-block;
    }

    strong {
      margin-right: 0.5em;
      text-align: right;
    }
  }

  &-trips {
    text-align: center;
    border-top: 1px solid $gray-light;

    > * {
      padding: 10px;

      &:not(:last-child) {
        border-right: 1px solid $gray-light;
      }
    }
  }

  &.smaller-size {
    span,
    strong,
    .referred-to-column {
      width: auto;
      text-align: left;
    }
  }
}
::v-deep .raised-tool-tip {
  z-index: 5001;
}

@media only screen and (max-width: 960px) {
  .deleted-warning {
    margin: 18px 0px 0px 0px;
  }
}
</style>
