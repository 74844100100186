<template>
  <div class="contract-quick-refer">
    <v-card-title>
      <h2>Contract #{{ contract.contractId }} Referral</h2>
    </v-card-title>
    <v-card-text>
      <v-layout class="affiliate-view" row wrap>
        <v-flex>
          <v-layout column class="referral-header">
            <v-layout row>
              <v-flex class="referral-header xs4">
                <v-flex xs11>
                  <v-layout column>
                    <div>Search</div>
                    <v-text-field
                      v-model="searchText"
                      append-icon="$vuetify.icons.search"
                      flat
                      solo
                      @input="filterAffiliates"
                    />
                  </v-layout>
                </v-flex>
                <v-flex xs1>
                  <v-layout
                    column
                    style="justify-content: center; height: 100%"
                  >
                    <div
                      style="cursor: pointer; width: 24px; height: 32px"
                      @click="affilType.sort = !affilType.sort"
                    >
                      <img
                        v-if="affilType.sort"
                        src="@/assets/images/sort_up.svg"
                      />
                      <img v-else src="@/assets/images/sort_down.svg" />
                    </div>
                  </v-layout>
                </v-flex>
              </v-flex>
              <v-flex>
                <div>Filter By Market</div>
                <MultiMarket :set-filter="setMarketFilter" />
              </v-flex>
            </v-layout>
          </v-layout>
        </v-flex>

        <v-flex v-if="!loading && !affiliatePage.length" text-xs-center xs12>
          No Data Available
        </v-flex>
        <v-flex xs12>
          <br />
        </v-flex>

        <v-flex v-if="loading" xs12>
          <v-layout row wrap>
            <v-flex text-xs-center xs12>
              Retrieving Affiliates
              <v-progress-linear height="2" indeterminate />
            </v-flex>
          </v-layout>
        </v-flex>

        <v-flex
          v-for="(affiliate, affiliateIndex) in affiliatePage"
          :key="affiliateIndex"
          xs12
        >
          <v-layout>
            <v-flex xs5>
              <v-layout style="height: 100%" column>
                <v-flex class="affiliate-row left-border-radius" align-center>
                  <div style="padding-left: 16px">
                    <div
                      style="
                        display: flex;
                        flex-wrap: wrap;
                        width: 80%;
                        word-wrap: break;
                      "
                    >
                      <strong class="affiliate-name">
                        {{ affiliate.name }}
                      </strong>
                    </div>

                    <div style="display: flex; align-items: center">
                      <v-chip
                        :color="$cr.theme.grayLight"
                        label
                        disabled
                        style="right: 5px"
                      >
                        {{ capitalize(affiliate.companyType) }}
                      </v-chip>
                      <v-avatar color="primary" size="30">
                        <span style="color: white">
                          {{ affiliate.referralCount }}
                        </span>
                      </v-avatar>
                      <template v-if="affiliate.preferred">
                        <img
                          style="margin-left: 8px"
                          src="@/assets/images/CharterUP.svg"
                        />
                      </template>
                      <template v-else>
                        <img
                          v-if="affiliate.partnerTypeId === 1"
                          style="margin-left: 8px"
                          src="@/assets/images/shofur.svg"
                        />
                        <img
                          v-if="affiliate.partnerTypeId === 2"
                          style="margin-left: 8px"
                          src="@/assets/images/gogo.svg"
                        />
                        <template v-if="affiliate.opinion < 0">
                          <v-tooltip top>
                            <template #activator="{ on }">
                              <v-icon
                                :color="'warning'"
                                style="margin-left: 8px"
                                v-on="on"
                              >
                                warning
                              </v-icon>
                            </template>
                            <span>Do Not Call / Do Not Use</span>
                          </v-tooltip>
                        </template>
                      </template>
                    </div>
                    <div class="affiliate-name">
                      {{ phoneFormatFilter(affiliate.phone) }}
                    </div>
                    <div
                      style="word-wrap: break; width: 90%"
                      class="affiliate-name"
                    >
                      {{ affiliate.email }}
                    </div>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>

            <v-flex xs2>
              <v-layout style="height: 100%" column>
                <v-flex class="affiliate-row" layout>
                  <div class="location-container">
                    <div class="garage-locations">
                      <div class="garage-container">
                        <div class="garage">
                          {{ affiliate.address.completeAddress }}
                        </div>
                        <div
                          v-for="(garage, gidx) in affiliate.garages"
                          :key="gidx"
                          class="garage"
                        >
                          {{ garage.address.completeAddress }}
                        </div>
                      </div>
                      <v-tooltip v-if="affiliate.garages.length > 1" right>
                        <template #activator="{ on }">
                          <div
                            :style="`color: ${$cr.theme.primary}; cursor: default;`"
                            v-on="on"
                          >
                            Show More
                          </div>
                        </template>
                        <div
                          v-for="(garage, gidx) in affiliate.garages"
                          :key="gidx"
                          class="garage"
                        >
                          {{ garage.address.completeAddress }}
                        </div>
                      </v-tooltip>
                    </div>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>

            <v-flex xs5>
              <v-layout column style="height: 100%">
                <v-layout
                  row
                  wrap
                  class="affiliate-row right-border-radius"
                  style="align-items: center; justify-content: center"
                >
                  <v-flex xs8>
                    <v-text-field
                      :id="`reservation-quick-refer-affiliate-${affiliateIndex}-text-offer-amount`"
                      :value="''"
                      class="no-spin-button"
                      placeholder="Enter referral amount"
                      flat
                      solo
                      type="number"
                      @input="
                        (offerAmount) =>
                          changeOfferAmount(affiliate, offerAmount)
                      "
                    />
                  </v-flex>
                </v-layout>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>

        <v-flex v-if="!loading && affiliatePage.length" xs12>
          <div>
            <v-pagination
              v-model="currentPage"
              :length="parseInt(totalPages, 10)"
              :total-visible="5"
              class="elevation-0 float-left"
              @input="changePage"
            />
          </div>
        </v-flex>
      </v-layout>
    </v-card-text>
    <div class="contract-quick-refer--secondary-spacer">
      <v-checkbox
        id="send-referral-email-checkbox"
        v-model="sendReferralEmail"
        hide-details
        class="contract-quick-refer--secondary-action"
        label="Send Referral Email"
      />
    </div>
    <v-layout justify-start>
      <div class="contract-quick-refer--btn-spacer">
        <v-btn
          id="save-button"
          class="contract-quick-refer--action-btn"
          style="margin: 0; border-radius: 0"
          color="primary"
          :loading="isOffering"
          @click="offerReferral"
        >
          Offer
        </v-btn>
      </div>
    </v-layout>
  </div>
</template>
<script>
import { capitalize } from '@/utils/string'
import { currencyFilter } from '@/utils/currency'
import { phoneFormatFilter } from '@/utils/phone'
import { filter } from '@/utils/filter'
import { sort } from '@/utils/sort'
import { authComputed } from '@/state/helpers'
import MultiMarket from '@/components/ModifiedMultimarkets.vue'
import admin from '@/services/admin'
import affiliates from '@/services/affiliates'
import { EventBus } from '@/utils/event-bus'
import { mapGetters } from 'vuex'

export default {
  components: {
    MultiMarket,
  },
  props: {
    mode: {
      type: String,
      default: 'enMasse',
    },
    contract: {
      type: Object,
      default: () => undefined,
    },
    reservations: {
      type: Array,
      default: () => undefined,
    },
  },
  data() {
    return {
      show: false,
      filters: {},
      requiredDrivers: 0,
      requiredPassengers: 0,
      requiredVehicles: [],
      filteredAffiliates: [],
      affiliates: [],
      affiliatePage: [],
      chosenAffiliates: {},
      currentPage: 1,
      perPage: 5,
      affilType: {
        sort: true,
        free: false,
        paid: false,
        gogo: false,
        shofur: false,
        charter: false,
      },
      loading: false,
      searchText: undefined,
      offeredMap: {},
      currencyFilter,
      phoneFormatFilter,
      markets: [],
      debounce: null,
      immediate: true,
      filterByCategory: 'name',
      totalPages: 100,
      isOffering: false,
      sendReferralEmail: false,
    }
  },
  computed: {
    ...authComputed,
    ...mapGetters({
      isReferralRejectionReasonV2Enabled:
        'featureToggles/isReferralRejectionReasonV2Enabled',
    }),
  },
  watch: {
    'affilType.sort'() {
      this.immediate = true
      this.filterAffiliates()
    },
  },
  async mounted() {
    this.show = true
    this.requiredDrivers = this.reservation?.driverCount
    this.requiredPassengers = this.reservation?.passengerCount
    this.requiredVehicles = this.reservation?.requiredVehicles
    this.loading = true

    this.filterAffiliates()
  },
  methods: {
    capitalize,
    setMarketFilter(e) {
      this.markets = e
      this.immediate = true
      this.filterAffiliates()
    },
    async filterAffiliates(arg) {
      if (this.debounce) {
        clearTimeout(this.debounce)
      }

      if (!this.immediate) {
        this.debounce = setTimeout(() => {
          this.immediate = true
          this.filterAffiliates(arg)
        }, 500)
        return
      }

      this.immediate = false
      const page = arg && arg.page ? arg.page : 1
      const filterObjects = []
      const affilSort = sort()
      const affilFilter = filter()

      if (this.markets.length > 0) {
        filterObjects.push({
          column: {
            _t_id: 'marketins',
            prop: 'nearestMarketId',
            filterType: 'eq',
          },
          value: this.markets.join(' '),
        })
      }

      if (this.searchText) {
        filterObjects.push({
          column: {
            _t_id: 'text_search_refer',
            prop: 'name',
            filterType: 'contains',
          },
          value: this.searchText,
        })
      }

      if (this.affilType.sort) {
        affilSort.add({
          prop: 'referralCount',
          direction: 'desc',
        })
      } else {
        affilSort.add({
          prop: 'referralCount',
          direction: 'asc',
        })
      }

      filterObjects.forEach((fil) => {
        const parent = affilFilter.createParent('and')
        affilFilter.add(parent, fil)
      })

      const filterParams = affilFilter.asQueryParams()
      const sortParams = affilSort.asQueryParams()

      const affilResponse = await affiliates.getAffiliates({
        sorts: sortParams,
        filters: filterParams,
        pageSize: 5,
        page,
      })

      this.affiliatePage = affilResponse?.data?.resultList.filter(
        (affiliate) => !!affiliate
      )
      this.loading = false

      this.immediate = true
    },
    changePage(currentPage) {
      this.currentPage = currentPage
      this.filterAffiliates({ page: currentPage })
    },
    affiliateGarage(affiliate) {
      return affiliate?.name || ''
    },
    affiliateLocation(affiliate) {
      return `${affiliate?.address?.city || ''}, ${
        affiliate?.address?.state || ''
      }`
    },
    async unassignReferral(affiliate) {
      const { referredTo: referrals = [] } = this.reservation
      const referral = referrals.find(
        (r) => r?.companyId === affiliate.companyId
      )
      if (referral) {
        const unassignReferralPayload = {
          reservationId: referral.reservationId,
        }
        if (this.isReferralRejectionReasonV2Enabled) {
          await this.$store.dispatch(
            'reservations/unassignReferral',
            unassignReferralPayload
          )
        } else {
          await this.$store.dispatch(
            'reservations/unassignReferral',
            unassignReferralPayload
          )
        }
      }
      this.$emit('refresh-query-request')
    },
    remapNewCompanyFormatToOld(company) {
      return [
        {
          company: { id: company.companyId },
          is_active: true,
          id: company.affiliateId,
        },
        {
          id: company.companyId,
        },
      ]
    },
    close() {
      this.show = false
      EventBus.$emit('global-table-view-refresh')
      this.$store.dispatch('app/closeDialog')
      this.$emit('close-modal')
    },
    async offerReferral() {
      this.isOffering = true
      let allNotOffered = true
      const reservationsIds = this.reservations.map((reservation) => {
        if (reservation.item) {
          reservation = reservation.item
        }
        if (reservation.referralStatus !== 'not_offered') {
          allNotOffered = false
        }
        return reservation.reservationId
      })

      if (!allNotOffered) {
        this.close()
        setImmediate(() => {
          this.$store.dispatch('app/showAlert', {
            type: 'error',
            message:
              'There are selected reservations that are already offered. Please select only Not Offered reservations and try again.',
          })
        })
        return
      }
      for (const [key, affiliate] of Object.entries(this.chosenAffiliates)) {
        const offerParams = {
          amount: affiliate.offerAmount,
          companyId: affiliate.companyId,
          reservationIds: reservationsIds,
          contractId: this.contract.contractId,
          sendReferralEmail: this.sendReferralEmail,
        }

        const response = await admin.createReferralsEnMasse(offerParams)

        if (response?.data?.successful) {
          await this.$store.dispatch('reservations/setManualReferralNeeded', {
            reservationIds: reservationsIds,
            needsManualReferral: true,
          })
        }
      }
      this.$emit('refresh-query-request')
      this.isOffering = false
      this.close()
      this.$store.dispatch('app/showAlert', {
        message: 'Referrals offered successfully.',
      })
    },
    changeOfferAmount(affiliate, offerAmount) {
      affiliate.offerAmount = parseFloat(offerAmount)
      if (affiliate.offerAmount > 0) {
        this.chosenAffiliates[affiliate.affiliateId] = affiliate
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.contract-quick-refer {
  display: flex;
  flex-direction: column;
  max-width: 700px;

  &__referral-chip {
    border-radius: 50px;
  }

  &--btn-spacer {
    min-height: 71px;
    width: 700px;
  }

  &--action-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    font-size: 18px;
    color: white;
    bottom: 0;
    width: inherit;
    height: 71px;
    padding: 24px 0;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    width: inherit;
    z-index: 3;
  }
  &--secondary-spacer {
    min-height: 54px;
    width: 700px;
  }

  &--secondary-action {
    position: fixed;
    font-size: 18px;
    background-color: white;
    bottom: 71px;
    width: 700px !important;
    width: inherit;
    margin: 0;
    padding: 10px 0 10px 16px;
    z-index: 1;
  }
}
.v-dialog {
  min-width: 750px !important;
  max-width: 1500px !important;
  .v-card {
    max-width: 1200px;
  }
}

.referral-header {
  display: flex;
  flex-grow: 10;
  justify-content: space-between;

  .flex {
    flex-grow: 2;
    padding-right: 10px;
    // padding-left: 10px;
  }

  .referral-filter {
    position: relative;
    display: flex;
    min-height: inherit;
    margin-bottom: 8px;
    margin-left: 20px;
    color: rgba($gray-base, 0.54);
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);

    div {
      display: flex;
      align-items: center;
      height: 2.5em;
      font-size: 16px;
      line-height: 1;
    }

    i {
      margin-left: 5px;
      font-size: 16px;
    }
  }
}

.display-number {
  margin-right: 10px;
  margin-left: 10px;
}

.vehicle-display {
  display: flex;
  flex-direction: column;
  width: 13%;

  > * {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 4%;
  }

  img {
    height: 30px;
  }
}

.location-container {
  display: flex;
  align-items: center;
  height: 120px;
  padding-bottom: 5px;

  .garage-locations {
    height: 40px;

    div {
      width: 100%;
    }

    .garage-container {
      height: inherit;
      overflow: scroll;

      .garage {
        color: $black;
      }
    }
  }
}

.vehicle-layout {
  .vehicle-label {
    height: 40px;
  }
}

.offer-layout ::v-deep input {
  color: $white !important;
  text-align: right;
}

.vehicle-layout ::v-deep input {
  color: $white !important;
  text-align: right;
}

.driver-layout ::v-deep input {
  color: $white !important;
  text-align: right;
}

.input-false ::v-deep input {
  color: $black !important;
}

.sort-options {
  display: flex;
  flex-grow: 1;
  padding-left: 2%;
}

.padded-left-4 {
  padding-right: 4px;
  padding-left: 4px;
}

.padded-left-8 {
  padding-right: 4px;
  padding-left: 8px;
}

.padded-left-6 {
  padding-right: 6px;
  padding-left: 6px;
}

.customer-total {
  height: 40px;
  color: $white;
  background-color: $black;
  border-radius: 4px;

  .padded {
    padding: 10px;
  }
}

.padded-margin-top {
  margin-top: 14px;
}

::v-deep .v-text-field {
  .v-input__control {
    .v-input__slot {
      background-color: $gray-light !important;
    }
  }
}

.affiliate-row {
  padding: 12px 6px 0 6px;
  margin-bottom: 4px;
  background-color: $blue-pale;

  .v-text-field {
    .v-input__control {
      min-height: 34px;
      max-height: 50px;

      .v-input__slot {
        margin-bottom: 0;
      }
    }

    input {
      padding: 0;
    }
  }
}

.affiliate-view > .flex.xs12 {
  margin-bottom: 20px;
}

.affiliate-name {
  color: $blue;
  word-wrap: break-word;
}

.light {
  color: $blue-dull;
}

.column-header {
  padding-left: 6px;
  min-height: 30px;
  // border-right: 1px solid $blue-dull;
  border-right: 1px solid rgba(128, 128, 128, 0.2);
}

.right-border-radius {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.left-border-radius {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
</style>

<style lang="scss" scoped>
.no-spin-button::v-deep input[type='number']::-webkit-inner-spin-button,
.no-spin-button::v-deep input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
</style>
